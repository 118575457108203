/* src/app/privacy/privacy.component.scss */
.header {
  padding: 20px 0 0;
  margin: 0;
}
.header h2 {
  font-size: 45px;
  padding: 0;
  margin: 0;
  text-align: center;
}
.content {
  font-size: 20px;
  padding-bottom: 30px;
  overflow-wrap: anywhere;
}
@media (max-width: 1140px) {
  .header h2 {
    font-size: 28px;
  }
  .content {
    font-size: 16px;
    padding: 0 20px 20px;
  }
}
/*# sourceMappingURL=privacy.component.css.map */

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppService } from '../app.services';

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss']
})
export class RedirectComponent implements OnInit {
  redirectUrl: string | undefined;
  param2: string | undefined;
  constructor(private route: ActivatedRoute, private appService:AppService) {
    this.route.queryParams.subscribe(params => {
      this.redirectUrl = params['rurl'];
      if(params['invId']){
        appService.saveRedirectData(params['rurl'],params['invId']);
      }
      window.location.replace(`http://${this.redirectUrl}`);
    });
  }
  ngOnInit(): void {
  }
}

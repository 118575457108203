/* src/app/app.component.scss */
.body {
  background-image: url("./media/background.png");
  background-repeat: no-repeat;
  background-position: top left;
}
.small-device {
  display: none;
}
.demo-section {
  background-color: #754BDB;
  height: 150px;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.demo-section a {
  background-color: #fff;
  color: #000;
  font-weight: 700;
  border-radius: 30px;
  font-size: 20px;
  padding: 8px 20px;
  text-decoration: none;
}
.demo-section p {
  padding: 0;
  margin: 0;
  font-weight: bold;
  color: #fff;
  margin-top: 10px;
}
@media (max-width: 1140px) {
  .small-device {
    display: inline-block !important;
  }
  .small-device-hide {
    display: none !important;
  }
}
.whatsapp {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}
.whatsapp img {
  height: 50px;
}
.header {
  max-width: 1200px;
  margin: auto;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
}
.header .logo {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  width: 300px;
}
.header .logo strong {
  font-size: 35px;
  letter-spacing: -0.04em;
  font-weight: 700;
  margin-top: 10px;
  display: inline-block;
}
.header .logo strong span {
  color: #FCBE0F;
}
.header .logo p {
  margin: 0;
  padding: 0;
  font-size: 11px;
  font-weight: 300;
}
.header .nav {
  margin-left: auto;
  margin-top: 15px;
}
.header .nav ul {
  padding: 0;
  margin: 0;
  display: flex;
  list-style: none;
}
.header .nav ul li {
  padding-left: 15px;
}
.header .nav ul li a {
  padding: 10px 10px;
  display: block;
  font-size: 16px;
  text-decoration: none;
  color: black;
}
.header .nav ul li a:hover {
  border-bottom: 3px solid #FCBE0F;
}
.header .nav ul li a.active {
  font-weight: bold;
  border-bottom: 3px solid #FCBE0F;
}
.header .nav ul .start-btn a {
  background-color: #FCBE0F;
  font-weight: 700;
  border-radius: 30px;
  padding: 10px 20px;
}
.header .nav ul .start-btn .cancel-anytime {
  text-align: center;
  margin-bottom: 15px;
  font-weight: 600;
}
.header .nav ul .demo-btn a {
  background-color: #754BDB;
  color: #fff;
  font-weight: 700;
  border-radius: 30px;
  padding: 10px 20px;
}
@media (max-width: 1140px) {
  .header .logo {
    text-align: center;
    width: auto;
    margin: auto;
  }
  .header .logo img {
    width: 70px;
  }
  .header .logo strong {
    font-size: 28px;
    margin-top: 20px;
  }
  .header .logo p {
    display: none;
  }
  .header .logo .img-logo {
    float: left;
  }
  .header .logo .text-logo {
    float: left;
  }
  .header .nav {
    display: none;
  }
  .header .nav.show-menu {
    display: block;
    background: #fff;
    border-radius: 0;
    position: fixed;
    top: 0;
    left: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    padding: 0;
    z-index: 10000;
  }
  .header .nav.show-menu ul {
    display: table;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    left: 0;
    padding: 0;
    margin: 0;
  }
  .header .nav.show-menu ul li {
    padding: 20px;
    margin: 0;
  }
  .header .nav.show-menu ul li a {
    font-size: 20px;
  }
  .header .nav.show-menu ul li.start-btn a {
    padding: 15px 0;
  }
  .header .nav.show-menu ul li.demo-btn {
    padding-top: 0;
  }
  .header .nav.show-menu ul li.demo-btn a {
    padding: 15px 0;
  }
  .header .logo-label {
    display: block !important;
  }
}
@media only screen and (max-width: 1200px) {
  .header .nav {
    padding-right: 15px;
  }
}
@media (max-width: 1140px) {
  .header {
    display: block;
    text-align: center;
  }
  .header .logo {
    width: 185px;
    padding-top: 5px;
  }
  .header .logo-label p {
    margin: auto;
    padding: 0;
    font-size: 11px;
    font-weight: 300;
    max-width: 250px;
    margin-top: -7px;
  }
  .header .menu {
    position: absolute;
    top: 0px;
    left: 0px;
    padding: 30px 20px;
    background: none;
    border: none;
  }
}
.influencer-app {
  background-color: #f8f8f8;
  padding: 50px 0;
  text-align: center;
}
.influencer-app .data {
  max-width: 1200px;
  margin: auto;
}
.influencer-app .data h3 {
  font-size: 33px;
  padding: 0;
  margin: 0;
}
.influencer-app .data p {
  font-size: 20px;
  padding: 10px 0 10px 0;
  margin: 0;
}
.influencer-app .data p strong {
  margin-top: 20px;
  display: block;
}
.influencer-app .data a {
  margin: 0 5px;
}
.influencer-app .data a img {
  height: 45px;
}
@media (max-width: 1140px) {
  .influencer-app {
    padding: 50px 15px;
  }
  .influencer-app .data h3 {
    font-size: 28px;
  }
  .influencer-app .data p {
    font-size: 16px;
  }
  .influencer-app .data a img {
    height: 40px;
  }
}
.footer {
  background-color: #202a42;
  color: #fff;
}
.footer .text-logo {
  font-size: 40px;
  letter-spacing: -1px;
  line-height: 1;
}
.footer .text-logo span {
  color: #FCBE0F;
}
.footer .data {
  max-width: 1200px;
  padding: 30px 15px;
  margin: auto;
}
.footer .data .flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.footer .data .flex .col {
  flex: 1 1 0;
  margin: 0 5px;
}
.footer .data .flex .col .heading {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 10px;
}
.footer .data .flex .col.company ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.footer .data .flex .col.company a {
  color: #fff;
  text-decoration: none;
  line-height: 25px;
}
.footer .data .flex .col.contact ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.footer .data .flex .col.contact li {
  display: flex;
  margin-bottom: 12px;
}
.footer .data .flex .col.contact li img {
  align-self: flex-start;
  margin-right: 10px;
}
.footer .data .flex .col.contact .social-icons {
  margin-top: 20px;
}
.footer .data .flex .col.contact .social-icons a {
  padding: 5px;
}
.footer .data .flex .col.newsletter .mat-form-field {
  width: 100%;
}
.footer .data .flex .col.newsletter .mat-form-field div {
  width: 100%;
  padding: 0;
  margin: 0;
  border-top: none;
}
.footer .data .flex .col.newsletter .enter-email {
  font-weight: 700;
  margin: 10px 0 5px;
}
.footer .data .flex .col.newsletter form {
  position: relative;
  width: 100%;
}
.footer .data .flex .col.newsletter form .spinner {
  position: absolute;
  top: 12px;
  right: 10px;
}
.footer .data .flex .col.newsletter form .spinner ::ng-deep circle {
  stroke: #FCBE0F;
}
.footer .data .flex .col.newsletter form input {
  background: rgba(255, 255, 255, 0.2);
  color: #fff;
  border: none;
  border-radius: 25px;
  padding: 15px 15px;
  width: calc(100% - 30px);
  margin-top: -19px;
}
.footer .data .flex .col.newsletter form input::placeholder {
  color: #fff;
}
.footer .data .flex .col.newsletter form button {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px 22px;
  font-size: 16px;
  border: none;
  background: #FCBE0F;
  color: black;
  font-weight: bold;
  border-radius: 25px;
}
.footer .data .flex p {
  padding: 0;
  margin: 0;
}
.footer .data .flex .first .invite {
  margin: 15px 0;
}
.footer .data .flex .first .influencer {
  margin-bottom: 10px;
}
.footer .data .flex .first .apps img {
  height: 40px;
}
.footer .data .flex .first .apps img:first-child {
  margin-right: 10px;
}
.footer .copyright {
  border-top: 1px solid #3e4557;
}
.footer .copyright .data {
  max-width: 1200px;
  margin: auto;
  padding: 10px 15px 8px;
}
.footer .copyright .data .flex {
  display: flex;
}
.footer .copyright .data .flex .text {
  font-size: 13px;
  color: #fff;
  line-height: 30px;
}
.footer .copyright .data .flex .text a {
  font-weight: 700;
  text-decoration: none;
  color: #fff;
}
.footer .copyright .data .flex .creditcard {
  margin-left: auto;
  padding-top: 4px;
}
.footer .copyright .data .flex .creditcard img {
  margin-left: 5px;
  height: 21px;
}
@media (max-width: 1140px) {
  .footer .data .flex {
    display: block;
  }
  .footer .data .flex .first .invite {
    margin: 30px 0;
  }
  .footer .data .flex .col {
    margin-bottom: 30px;
  }
  .footer .data .flex .col.newsletter {
    margin-bottom: 0;
  }
}
.pages {
  max-width: 1200px;
  margin: auto;
}
/*# sourceMappingURL=app.component.css.map */

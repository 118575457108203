<ng-template [ngIf]="!showCookiesConsentBar">
    <div class="cookies-bar">
        <div class="cookies-bar-text">
            <div class="text">We use cookies to improve your experience on our site. <br> Clicking the Continue button, or continuing to use the site, means you agree with our <a routerLink="/privacy/" fragment="cookiesPolicy">Cookie policy</a>.</div>
            <div class="buttons-container">
                <div class="buttons-row">
                    <button class="btn btn-primary" (click)="closeCookiesConsent(true)">
                        Continue
                    </button>
                    <a (click)="closeCookiesConsent(false)">
                        <img width="20" src="assets/close-icon.png" alt=""/>
                    </a>
                </div>
            </div>
        </div>
    </div>
</ng-template>
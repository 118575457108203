import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { InfluencerComponent } from './influencer/influencer.component';
import { FeaturesComponent } from './features/features.component';
import { TermsComponent } from './terms/influencer/terms.component';
import { AppService } from './app.services';
import { PrivacyComponent } from './privacy/privacy.component';
import { TermsBusinessComponent } from './terms/business/terms.component';
import { RedirectComponent } from './redirect/redirect.component';


const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'about', component: AboutComponent },
  { path: 'influencer', component: InfluencerComponent },
  { path: 'features', component: FeaturesComponent },
  // { path: 'contact', component: ContactComponent },
  { path: 'terms', component: TermsComponent },
  { path: 'terms-business', component: TermsBusinessComponent },
  { path: 'privacy', component: PrivacyComponent },
  { path: 'redirect', component: RedirectComponent },
];

const routerOptions: ExtraOptions = {
    useHash: false,
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled'
};

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  providers:[ AppService ],
  exports: [RouterModule]
})
export class AppRoutingModule { }

/* src/app/influencer/influencer.component.scss */
.influencers .header {
  text-align: center;
  padding: 30px 0;
}
.influencers .header h2 {
  font-size: 40px;
  padding: 0;
  margin: 0;
}
.influencers .header .buzzin {
  font-size: 24px;
  padding: 0;
  margin: 0;
}
.influencers .header p {
  font-size: 20px;
}
.influencers .header .signup {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}
.influencers .header .app-links a {
  padding: 0 5px;
}
.influencers .header .app-links img {
  height: 53px;
}
.influencers .content {
  text-align: center;
}
.influencers .content h4 {
  font-size: 35px;
}
.influencers .content .steps {
  display: flex;
  max-width: 960px;
  margin: auto;
  position: relative;
}
.influencers .content .steps .step {
  flex: 1 1 0;
  padding: 0 20px;
  z-index: 1;
  font-size: 20px;
}
.influencers .content .steps:before {
  content: "";
  width: 20%;
  position: absolute;
  border-bottom: 1px dashed #000;
  letter-spacing: 4px;
  height: 1px;
  left: 23%;
  top: 30px;
}
.influencers .content .steps:after {
  content: "";
  width: 20%;
  position: absolute;
  border-bottom: 1px dashed #000;
  height: 1px;
  right: 23%;
  top: 30px;
}
.influencers .content .wishs-background {
  background-color: #FFF9E7;
  padding: 50px;
  border-radius: 25px;
  margin-top: 50px;
}
.influencers .content .wishs-background h4 {
  font-size: 35px;
  margin: 0 0 30px 0;
  padding: 0;
}
.influencers .content .wishs-background .wishs {
  display: flex;
}
.influencers .content .wishs-background .wishs .wish {
  flex: 1 1 0;
}
.influencers .content .who-join {
  text-align: left;
  font-size: 20px;
  padding: 50px 0;
}
.influencers .content .who-join h4 {
  padding: 0;
  margin: 0;
  margin-bottom: 10px;
}
.influencers .content .who-join ul,
.influencers .content .who-join li {
  list-style: none;
}
@media (max-width: 1140px) {
  .influencers .header {
    text-align: center;
    padding: 30px 20px;
  }
  .influencers .header h2 {
    font-size: 28px;
    padding: 0;
    margin: 0;
  }
  .influencers .header .buzzin {
    font-size: 16px;
    padding: 0;
    margin: 0;
  }
  .influencers .header p {
    font-size: 16px;
  }
  .influencers .header .signup {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .influencers .header .app-links a {
    padding: 0 5px;
  }
  .influencers .header .app-links img {
    height: 40px;
  }
  .influencers .content {
    text-align: center;
  }
  .influencers .content h4 {
    font-size: 22px;
  }
  .influencers .content .steps {
    display: block;
    max-width: 900px;
    margin: auto;
    position: relative;
    margin-bottom: 40px;
  }
  .influencers .content .steps .step {
    flex: 1 1 0;
    padding: 0 20px 20px;
    z-index: 1;
    font-size: 16px;
  }
  .influencers .content .steps .step img {
    height: 50px;
  }
  .influencers .content .steps:before {
    display: none;
  }
  .influencers .content .steps:after {
    display: none;
  }
  .influencers .content .wishs-background {
    background-color: #FFF9E7;
    padding: 50px 30px;
    border-radius: 0px;
    margin: 0px;
  }
  .influencers .content .wishs-background h4 {
    font-size: 22px;
    margin: 0 0 40px 0;
    padding: 0;
  }
  .influencers .content .wishs-background .wishs {
    display: block;
  }
  .influencers .content .wishs-background .wishs .wish {
    margin-top: 30px;
  }
  .influencers .content .wishs-background .wishs .wish img {
    height: 180px;
  }
  .influencers .content .who-join {
    padding: 50px 30px;
    font-size: 16px;
  }
}
/*# sourceMappingURL=influencer.component.css.map */

/* src/app/features/features.component.scss */
.small-device {
  display: none;
}
@media (max-width: 1140px) {
  .small-device {
    display: inline-block !important;
  }
  .small-device-hide {
    display: none !important;
  }
}
.signup-btn {
  margin: auto;
  display: table;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 50px;
}
.signup-btn a {
  background-color: #FCBE0F;
  font-weight: 700;
  border-radius: 50px;
  padding: 15px 40px;
  color: black;
  font-size: 26px;
  text-decoration: none;
}
.signup-btn .or {
  padding: 10px 0;
  color: #000;
  display: block;
}
.signup-btn a.demo {
  background-color: #754BDB;
  color: #fff;
}
.signup-btn small {
  display: block;
  font-size: 17px;
  font-weight: bold;
  margin-top: 25px;
}
@media (max-width: 1140px) {
  .signup-btn a {
    font-size: 20px;
    padding: 13px 20px;
    display: block;
  }
  .signup-btn small {
    font-size: 13px;
    margin-top: 10px;
  }
}
.features {
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 0 20px;
}
.features h1 {
  font-size: 42px;
  padding: 0;
  margin: 0;
}
.features p {
  font-size: 20px;
  padding: 0;
  margin: 10px 0 0 0;
}
.features .feature {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 30px 0;
  align-items: center;
}
.features .feature .col {
  flex: 1 1 0;
}
.features .feature .col h2 {
  font-size: 32px;
  padding: 0;
  margin: 10px 0 0 0;
}
.features .feature .col p {
  font-size: 20px;
  padding: 0;
  margin: 5px 0 0 0;
}
.features .feature .col img {
  height: 50px;
}
.features .feature .col.left {
  text-align: right;
}
.features .feature .col.right,
.features .feature .col.left {
  padding: 0 40px;
}
.features .feature .col.right img,
.features .feature .col.left img {
  height: 300px;
}
@media (max-width: 1140px) {
  .features {
    padding: 0 20px;
  }
  .features h1 {
    font-size: 28px;
  }
  .features p {
    font-size: 16px;
  }
  .features .feature {
    display: block;
    width: 100%;
  }
  .features .feature .col {
    display: block;
  }
  .features .feature .col img {
    height: 35px;
  }
  .features .feature .col h2 {
    font-size: 22px;
  }
  .features .feature .col.right,
  .features .feature .col.left {
    padding: 0;
  }
  .features .feature .col.right img,
  .features .feature .col.left img {
    height: auto;
    width: 100%;
    max-width: 300px;
  }
  .features .feature .col.left {
    display: none;
  }
}
/*# sourceMappingURL=features.component.css.map */

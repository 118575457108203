/* src/app/components/cookies-consent/cookies-consent.component.scss */
.cookies-bar {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: black;
  display: flex;
  z-index: 3;
  color: white;
  justify-content: center;
  align-items: center;
}
.cookies-bar-text {
  flex-wrap: wrap;
  max-width: 1200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}
.buttons-row > button {
  border-radius: 50px;
  border: none;
  width: 100px;
  height: 40px;
  background-color: #fcbe0f;
  text-align: end;
  color: black;
  text-align: center;
  margin: 10px 0;
}
.buttons-row {
  width: 150px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.text {
  flex: 0 0 67%;
  width: 67%;
}
.buttons-container {
  flex: 0 0 33%;
  width: 33%;
  display: flex;
  justify-content: flex-end;
}
@media screen and (max-width: 980px) {
  .cookies-bar {
    z-index: 10001;
  }
  .buttons-row {
    width: 100%;
  }
  .text {
    flex: 0 0 100%;
    width: 100%;
  }
  .buttons-container {
    flex: 0 0 100%;
    width: 100%;
  }
}
/*# sourceMappingURL=cookies-consent.component.css.map */

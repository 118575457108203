/* src/app/home/home.component.scss */
.headline {
  text-align: center;
  padding: 30px 100px;
}
.headline strong {
  font-size: 40px;
  font-weight: 400;
}
.headline strong span {
  font-weight: 700;
}
@media (max-width: 1140px) {
  .headline {
    padding: 30px 15px;
  }
  .headline strong {
    font-size: 25px;
  }
}
.signup-btn {
  margin: auto;
  display: table;
  text-align: center;
  margin-top: 10px;
}
.signup-btn a {
  background-color: #FCBE0F;
  font-weight: 700;
  border-radius: 50px;
  padding: 15px 40px;
  color: black;
  font-size: 26px;
  text-decoration: none;
}
.signup-btn small {
  display: block;
  font-size: 17px;
  font-weight: bold;
  margin-top: 25px;
}
.cancel-anytime {
  text-align: center;
  margin-bottom: 15px;
  font-weight: 600;
  margin-top: 20px;
}
@media (max-width: 1140px) {
  .signup-btn a {
    font-size: 20px;
    padding: 13px 20px;
    display: block;
  }
  .signup-btn small {
    font-size: 13px;
    margin-top: 10px;
  }
}
p {
  text-align: center;
  font-size: 22px;
  font-weight: bold;
  margin: 40px 0;
}
@media (max-width: 1140px) {
  p {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    margin: 40px 20px;
  }
}
.how-it-works {
  text-align: center;
}
.how-it-works h3 {
  font-size: 30px;
  margin: 0 0 15px 0;
  padding: 0;
}
@media (max-width: 1140px) {
  .how-it-works h3 {
    font-size: 22px;
    margin: 0 0 15px 0;
    padding: 0;
  }
  .how-it-works iframe {
    max-width: 600px;
    width: 100%;
    height: 320px;
  }
}
.steps {
  display: flex;
  margin: auto;
  justify-content: center;
  margin-top: 50px;
}
.steps .step {
  text-align: center;
  width: 250px;
  padding: 0 20px;
}
.steps .step strong {
  display: block;
  font-size: 18px;
  margin-bottom: 18px;
}
.steps .step img {
  margin-bottom: 10px;
}
.steps .step p {
  font-size: 14px;
  font-weight: 400;
  padding: 0;
  margin: 0;
}
.subscription-packages-container {
  max-width: 900px;
  margin: 50px auto;
  padding: 0 20px;
}
@media (max-width: 1140px) {
  .steps {
    display: block;
  }
  .steps .step {
    margin: 0 auto;
    margin-bottom: 30px;
  }
}
.our-clients {
  text-align: center;
  font-size: 30px;
  max-width: 800px;
  margin: 50px auto;
}
.our-clients h3 {
  margin: 0;
  padding: 0;
  font-size: 24px;
}
.our-clients .clients {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  margin: auto;
  max-width: 90vw;
}
.our-clients .clients .logo {
  width: 160px;
}
.our-clients .clients .logo img {
  width: 100%;
}
@media (min-width: 1200px) {
  .testimonials {
    width: 100vw;
    margin-left: calc((-100vw + 1200px) / 2);
  }
}
.testimonials {
  background-color: #f8f8f8;
  padding: 40px 0;
  text-align: center;
}
.testimonials h3 {
  margin: 0;
  margin-bottom: 20px;
  padding: 0;
  font-size: 24px;
}
.testimonials .testimonials-row-wrapper {
  max-width: 780px;
  margin: auto;
  overflow: auto;
}
.testimonials .testimonials-row {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}
.testimonials .testimonials-row .testimonial {
  scroll-snap-align: start;
  font-size: 12px;
  width: 260px;
  margin: 15px 0;
  display: block;
  position: relative;
  flex-shrink: 0;
}
.testimonials .testimonials-row .testimonial .quote-icon {
  position: absolute;
}
.testimonials .testimonials-row .testimonial .quote-icon img {
  width: 17px;
}
.testimonials .testimonials-row .testimonial .quote {
  padding: 5px 20px;
}
.testimonials .testimonials-row .testimonial .quote-author-container {
  margin-top: 20px;
}
.testimonials .testimonials-row .testimonial .quote-author-container .quote-author-name {
  font-size: 14px;
  font-weight: 600;
}
.testimonials .testimonials-row .testimonial .quote-author-container .quote-author-brand {
  margin-top: 10px;
  font-size: 14px;
  color: #fbbe0d;
  font-weight: 600;
}
.testimonials .testimonials-row .testimonial .quote-author-container .quote-author-brand {
  margin-top: 10px;
  font-size: 14px;
  color: #fbbe0d;
  font-weight: 600;
}
.testimonials .testimonials-row::-webkit-scrollbar {
  width: 50px;
  height: 10px;
}
@media (max-width: 1140px) {
  .testimonials .testimonials-row-wrapper {
    max-width: 260px;
  }
}
@media (max-width: 1140px) {
  .our-clients h3 {
    font-size: 22px;
  }
  .our-clients .clients .logo {
    width: 120px;
  }
}
.pricing {
  margin-top: 50px;
  font-size: 20px;
  margin-bottom: 50px;
}
.pricing h3 {
  font-size: 30px;
  text-align: center;
}
.pricing .package {
  padding: 15px 10px;
  position: relative;
}
.pricing .package .best-selling {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 10px 10px 0 0;
  padding: 7px 30px;
  text-transform: uppercase;
  color: #fff;
  background: red;
  font-size: 11px;
  font-weight: bold;
}
.pricing .package .box {
  background: #ffffff;
  box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.17);
  border-radius: 15px;
  padding: 30px 20px;
  margin-top: 14px;
}
.pricing .package .box .name {
  text-align: center;
  height: 50px;
}
.pricing .package .box .name div {
  text-align: center;
}
.pricing .package .box .name div strong {
  font-size: 26px;
}
.pricing .package .box .name div strong.orange {
  color: #fd7c27;
}
.pricing .package .box .name div strong.gray {
  color: #9b9b9b;
}
.pricing .package .box .name div strong.purple {
  color: #543f95;
}
.pricing .package .box .name div small {
  font-size: 13px;
  margin-top: -2px;
  display: block;
}
.pricing .package .box .price {
  display: table;
  margin: 10px auto;
  height: 100px;
}
.pricing .package .box .price .per {
  font-size: 12px;
}
.pricing .package .box .price .amount {
  font-size: 40px;
  font-weight: bold;
  display: inline-flex;
  margin-right: 5px;
}
.pricing .package .box .price .amount span {
  font-size: 16px;
  line-height: 30px;
}
.pricing .package .box .price .top {
  display: flex;
  align-items: center;
  justify-content: center;
}
.pricing .package .box .price .bottom {
  display: flex;
  align-items: center;
  justify-content: center;
}
.pricing .package .box .price .bottom .amount {
  font-size: 25px;
  text-decoration: line-through;
  font-weight: normal;
}
.pricing .package .box .price .bottom .saving-limit {
  font-size: 12px;
}
.pricing .package .box .price .bottom .saving-limit .limit {
  color: red;
}
.pricing .package .box .list .item {
  display: flex;
  align-items: flex-start;
  font-size: 16px;
  margin-bottom: 10px;
}
.pricing .package .box .list .item img {
  margin-right: 10px;
}
@media (max-width: 1140px) {
  .pricing .package .box .list .item {
    font-size: 16px;
  }
}
.pricing .package .box .billed {
  font-size: 16px;
  font-style: italic;
  font-weight: bold;
  height: 16px;
  line-height: 1;
  display: block;
  margin-top: 20px;
}
.pricing .signup-btn {
  margin-top: 50px;
}
@media (max-width: 1140px) {
  .pricing .signup-btn {
    margin-top: 20px;
  }
}
/*# sourceMappingURL=home.component.css.map */

import { Component, OnInit } from '@angular/core';
import { AppService } from '../app.services';

@Component({
  selector: 'app-subscription-packages',
  templateUrl: './subscription-packages.component.html',
  styleUrls: ['./subscription-packages.component.scss'],
  providers: [AppService]
})
export class SubscriptionPackagesComponent implements OnInit {

  subscriptionPackages = [{
    "currencyId": "AED",
    "numberOfCampaigns": 0,
    "installments": true,
    "unlimitedNumberOfRequiredInfluencersPerBrand": false,
    "unlimitedDuration": true,
    "upgradable": true,
    "name": "30 For 5500 AED",
    "numberOfRequiredInfluencersPerCampaign": 0,
    "numberOfInvitedInfluencersPerCampaign": 0,
    "paymentMethods": [
        "card"
    ],
    "priceGBP": 159,
    "bespokePackage": false,
    "durationInMonths": 12,
    "unlimitedNumberOfRequiredInfluencersPerCampaign": true,
    "hasMultiBrandsAccess": true,
    "canCreateDiscountCampaigns": true,
    "discountCode": null,
    "displayOrder": 4,
    "label": "Enterprise",
    "numberOfRequiredInfluencersPerBrand": 30,
    "offers": [],
    "unlimitedNumberOfInvitedInfluencersPerCampaign": true,
    "unlimitedNumberOfCampaigns": true,
    "billingIntervalInMonths": 1,
    "price": 5500,
    "hasMultiUsersAccess": true,
    "suitableFor": "Perfect for awareness and sales",
    "isAvailableForEveryone": true,
    "features": [
        {
            "label": "Partner with Instagram and TikTok influencers"
        },
        {
            "label": "View and approve influencers"
        },
        {
            "label": "Access influencer audience data"
        },
        {
            "label": "Chat with influencers"
        },
        {
            "label": "Download and reshare influencer content"
        },
        {
            "label": "View performance analytics and reports"
        },
        {
            "label": "Launch seeding & barter campaigns "
        },
        {
            "label": "Launch discount campaigns "
        },
        {
            "label": "Receive full campaign management"
        },
        {
            "label": "Book a consultation session or demo"
        }
    ],
    "packageType": "Package E",
    "packageOrder": 4
}];
  subscriptionPackagesToDisplay = [{
    "currencyId": "AED",
    "numberOfCampaigns": 0,
    "installments": true,
    "unlimitedNumberOfRequiredInfluencersPerBrand": false,
    "unlimitedDuration": true,
    "upgradable": true,
    "name": "30 For 5500 AED",
    "numberOfRequiredInfluencersPerCampaign": 0,
    "numberOfInvitedInfluencersPerCampaign": 0,
    "paymentMethods": [
        "card"
    ],

    "bespokePackage": false,
    "durationInMonths": 12,
    "unlimitedNumberOfRequiredInfluencersPerCampaign": true,
    "hasMultiBrandsAccess": true,
    "canCreateDiscountCampaigns": true,
    "discountCode": null,
    "displayOrder": 4,
    "label": "Enterprise",
    "numberOfRequiredInfluencersPerBrand": 30,
    "offers": [],
    "unlimitedNumberOfInvitedInfluencersPerCampaign": true,
    "unlimitedNumberOfCampaigns": true,
    "billingIntervalInMonths": 1,
    "hasMultiUsersAccess": true,
    "suitableFor": "Perfect for awareness and sales",
    "isAvailableForEveryone": true,
    "features": [
        {
            "label": "Partner with Instagram and TikTok influencers"
        },
        {
            "label": "View and approve influencers"
        },
        {
            "label": "Access influencer audience data"
        },
        {
            "label": "Chat with influencers"
        },
        {
            "label": "Download and reshare influencer content"
        },
        {
            "label": "View performance analytics and reports"
        },
        {
            "label": "Launch seeding & barter campaigns "
        },
        {
            "label": "Launch discount campaigns "
        },
        {
            "label": "Receive full campaign management"
        },
        {
            "label": "Book a consultation session or demo"
        }
    ],
    "packageType": "Package E",
    "packageOrder": 4
}];
  selectedPackage: any;
  // constructor(appService: AppService) {
  //   appService.getSubscriptionPackages().then((subscriptionPackages: Array<any>) => {
  //     console.log(subscriptionPackages);
  //     this.subscriptionPackages = subscriptionPackages;
  //     this.subscriptionPackages.forEach((subscriptionPackage) => {
  //       if (subscriptionPackage.isAvailableForEveryone) {
  //         this.subscriptionPackagesToDisplay.push(subscriptionPackage);
  //         if(subscriptionPackage.isMostPopular){
  //           this.selectedPackage = subscriptionPackage;
  //         }
  //       }
  //     });
      
  //   });
  // }

  ngOnInit(): void {
  }

  selectPackage(selectedPackage: any) {
    this.selectedPackage = selectedPackage;
  }

}

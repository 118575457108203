<div class="inner-page">
  <div class="header">
    <h2>Terms of Use</h2>
    <strong>Influencer</strong>
  </div>
  <div class="content">
    <p><strong>BACKGROUND </strong></p>
    <p><strong>&nbsp;</strong></p>
    <p><strong>What is BuzzBee? </strong>&nbsp;</p>
    <p>
      BuzzBee is a service accessible through mobile applications available to
      influencers and businesses, which connect businesses with local
      influencers in the same area. The businesses can offer free or discounted
      services and goods to their chosen influencers in exchange for promotion
      of their business on social media. BuzzBee is owned and operated by Brand
      Ripplr FZ-LLC, a free zone limited liability company established in
      Creative City, Media Free Zone, Fujairah under License number 11151/2017,
      Fujairah, United Arab Emirates (&ldquo;Brand Ripplr&rdquo;,
      &ldquo;us&rdquo;, &ldquo;we&rdquo;, &ldquo;our&rdquo;).
    </p>
    <p>&nbsp;</p>
    <p><strong>BuzzBee influencer mobile application</strong></p>
    <p>
      The influencer application is a mobile application (the
      &ldquo;Application&rdquo;) for people who create promotional online media
      content (each an &ldquo;Influencer&rdquo; and together the
      &ldquo;Influencers&rdquo;) for businesses. The Application facilitates the
      matching of Influencers with businesses registered with Brand Ripplr who
      want to offer products and/or services to the Influencers (each known as
      an &ldquo;Event Initiator&rdquo; and together the &ldquo;Event
      Initiators&rdquo;) at their event(s) (each an &ldquo;Event&rdquo; and
      together the &ldquo;Events&rdquo;). Any services provided in connection
      with the Application are known as the &ldquo;Application Services&rdquo;.
      The term &ldquo;you&rdquo; shall refer to the Influencer as the context
      may require.
    </p>
    <p>&nbsp;</p>
    <p><strong>How do I sign up? </strong></p>
    <p>
      You can download and install the Application and sign up for a user
      account (the &ldquo;User Account&rdquo;) in order to access and use the
      Application and Application Services. You must: (1) keep your User Account
      details secure; (2) ensure that you do not share your User Account with
      anyone else; and (3)not collect or harvest any personal data of any other
      user of the Application, including account names. You acknowledge and
      agree that the User Account is created and used for the sole purpose of
      utilising the Application Services (defined above).
    </p>
    <p>&nbsp;</p>
    <p><strong>Application Services</strong></p>
    <p>
      Through the Application you can avail of the following application
      services (the &ldquo;Application Services&rdquo;) : (1) obtain information
      about Events (including but not limited to details about the Events (which
      you may also receive through electronic mail);&nbsp; (2) respond to/post
      your interest in/accept an invitation to an Event; (3) book an appointment
      or make a reservation for an Event if applicable; (4) post any
      clarification questions about an Event; (5) communicate with Event
      Initiators; and (6) rate and review the Events and Event Initiators as
      applicable. Please note that Event Initiators also have the ability to
      rate and review Influencers which may impact future selection of
      Influencers for Events. The higher the Influencer rating, the more Event
      invitations they will receive and in turn they will earn more rewards and
      recognition.
    </p>
    <p>
      You agree that you are solely responsible for all communication between
      you and the Event Initiator through the Application or otherwise. Your
      election to share your data and information (which may include your
      personally identifiable information) with Event Initiators, or
      solicitation or acceptance of an Event will serve as your affirmative
      &ldquo;opt in&rdquo; to the disclosure by Brand Ripplr of your, data, and
      information (which may include your personally identifiable information)
      to the Event Initiator.
    </p>
    <p>&nbsp;</p>
    <p><strong>What are the next steps?</strong></p>
    <p>
      When you are selected for an Event, you will receive an invitation from an
      Event Initiator through the Application. The invitation will include the
      details of the Event and you can choose to accept or decline the
      invitation. If you accept the invitation, you must attend at the allocated
      time, date and location of the Event. You will produce a post(s) that will
      promote the Event in line with the brief for the Event and to publish the
      post(s) within seven (7) days from the date of the Event.&nbsp; You are
      not required to submit your post(s) for pre-approval by Brand Ripplr
      and/or the Event Initiator. You will link your published post(s) with the
      Application and the Event Initiators will have the ability but are not
      required to repost your posts and/or to credit you.
    </p>
    <p>
      Influencers must not publish posts twenty-four (24) hours before or
      twenty-four (24) hours after an Event which directly or otherwise promotes
      a competitor(s) of the Event Initiator or the Event. If you are in doubt
      who the competitors of the Event Initiator and/or the Event are please
      confirm this with the Event Initiator before the Event.
    </p>
    <p><strong>&nbsp;</strong></p>
    <p><strong>TERMS AND CONDITIONS OF USE </strong></p>
    <p>
      The following terms and conditions of use, or any future amendment thereof
      (the &ldquo;Terms and Conditions of Use&rdquo;) govern the access to and
      use of the Application. These Terms and Conditions of Use (together with
      our Privacy and Cookies Policies (as set out below) together the
      &ldquo;Terms&rdquo;)) form an agreement between Brand Ripplr and you and
      shall be the sole agreement between us. These Terms and Conditions of Use
      are not intended to give rights to anyone except you and us.&nbsp;
    </p>
    <p><strong>&nbsp;</strong></p>
    <p><strong>User Account and the Application </strong></p>
    <p>
      Please read these Terms and Conditions of Use carefully before you
      download, access, browse or use the Application and/or Application
      Services. By downloading, accessing, browsing, creating or utilising a
      User Account, the Application (the term &ldquo;use&rdquo; when used herein
      in respect of the Application shall mean access or use, and using shall
      have a corresponding meaning) or the underlying services you are accepting
      and agreeing to the Terms and Conditions of Use, as they may be amended
      from time to time. If you are not willing to be bound by these Terms and
      Conditions of Use, you should not download, access, browse or use the
      Application or create a User Account.
    </p>
    <p>
      If you are creating a User Account, accessing or using the Application on
      behalf of another person or a corporate entity or if you are acting as an
      agent, you represent and warrant that you have the authority to bind such
      person or entity to these Terms and Conditions of Use. You are solely
      responsible for ensuring that the Terms and Conditions of Use are in
      compliance with applicable laws, rules and regulations applicable to you.
    </p>
    <p>
      By creating a User Account and accessing or using the Application, you
      represent, warrant and covenant that you provide to Brand Ripplr accurate,
      truthful, complete and non-misleading information. It is your obligation
      to keep your registration information accurate and up to date. Failure to
      do so shall constitute a breach of these Terms and Conditions of Use,
      which may result in immediate cancellation of your User Account. Brand
      Ripplr reserves the right to disable any User Account issued to you at any
      time in Brand Ripplr&rsquo;s sole discretion. If Brand Ripplr disables
      access to a User Account issued to you, you may be prevented from
      accessing the Application, your account details or any Events that are
      associated with your account.
    </p>
    <p>
      You are responsible for safeguarding the password that you use to access
      the User Account and for any activities or actions under your password,
      whether your password is with our service or a third-party service. You
      are fully responsible for all liabilities and damages incurred through the
      use of your User Account (whether lawful or unlawful) and that any
      transactions completed through any User Account will be deemed to have
      been lawfully completed by you. You agree not to disclose your password to
      any third party. You must notify us immediately upon becoming aware of any
      breach of security or unauthorised use of your User Account. Failure to do
      so will constitute a material breach of these Terms and Conditions of Use.
    </p>
    <p>&nbsp;</p>
    <p>
      <strong>Changes to these Terms </strong>and Conditions
      <strong>of Use and Application</strong>
    </p>
    <p>
      Except where prohibited by applicable law, we reserve the right to change
      these Terms and Conditions of Use at any time without notice. Your
      continued access to or use of the Application after any changes to these
      Terms and Conditions of Use indicates your acceptance of such changes. It
      is your responsibility to review these Terms and Conditions of Use
      regularly.&nbsp;
    </p>
    <p>
      We reserve the right to change, modify, substitute, suspend or remove any
      of the Application Services on the Application or information, material or
      content (including,&nbsp;but not limited to, features of the Application,
      Event Initiator profiles and details of the Events etc) all designs, IPR,
      infrastructure graphics, pictures, illustrations, software, artwork,
      video, music, sound, names, words, titles, phrases, logos and marks
      displayed or provided on or through the Application (the
      &ldquo;Application Content&rdquo;) at any time, and from time to time,
      without notice.
    </p>
    <p>
      Your access to the Application and/or the Application Services may also be
      occasionally restricted to allow for repairs, maintenance or the
      introduction of new facilities or services. We will attempt to restore
      such access as soon as we reasonably can.
    </p>
    <p>
      If you encounter any errors or omissions with the Application, please
      notify us by emailing
      <a href="mailto:support@buzzbee.com">support&#64;buzzbee.com</a> as soon as
      possible. We cannot confirm that the Application and the Application
      Services will be free from faults nor can we accept liability for any such
      faults, errors or omissions (please refer to the Limitation of Liability
      paragraph below).
    </p>
    <p><strong>&nbsp;</strong></p>
    <p><strong>Use of the Application </strong></p>
    <p>
      As a condition of your use of the Application, you warrant, represent and
      covenant that: (a) you are sixteen (16) years of age or older; (b) you
      possess the legal authority to create a binding legal obligation; (c) you
      shall use the Application in accordance with these Terms and Conditions of
      Use; and (d) all information supplied by you on the Application is true,
      accurate, current, not misleading and complete. If you are between the
      ages of sixteen (16) and eighteen (18) years old, parental consent will be
      required before you can use the Application.
    </p>
    <p>
      We reserve the right, at our sole discretion to reject, block and/or deny
      access to anyone to the Application or the Application Services and/or to
      edit or remove any material which in our reasonable opinion may give rise
      to a breach of&nbsp; these Terms and Conditions of Use. You shall cease
      and desist from any such access or use immediately upon request by Brand
      Ripplr.
    </p>
    <p>&nbsp;</p>
    <p><strong>Brand Ripplr License </strong></p>
    <p>
      Subject to these Terms and Conditions of Use, Brand Ripplr grants you a
      personal, revocable, non-exclusive and non-transferable, non sub
      licensable license during the Term to permit you to access and use the
      Application in accordance with these Terms and Conditions of Use. The
      Application, and the databases, software, hardware and other technology
      used by or on behalf of Brand Ripplr to operate the Application, and the
      structure, organisation, and underlying data, information and software
      code thereof, constitute valuable trade secrets of Brand Ripplr. The
      Application and any portion thereof may not be reproduced, duplicated,
      copied, reverse engineered, downloaded, sold, resold, visited, or
      otherwise exploited for any commercial purpose without the express written
      consent of Brand Ripplr. You may not frame or utilise framing techniques
      to enclose any trademark, logo or other proprietary information (including
      images, text, page layout and form) of Brand Ripplr without our express
      written consent. You may not use any meta tags or any other &ldquo;hidden
      text&rdquo; utilising Brand Ripplr&rsquo;s name or trademarks without our
      express written consent. Any unauthorised use terminates the license
      granted by Brand Ripplr.
    </p>
    <p>
      Subject to these Terms and Conditions of Use, you grant to Brand Ripplr, a
      perpetual, transferrable, irrevocable, royalty-free, fully paid-up,
      worldwide and fully sub licensable license to access, collect, store and
      use any published posts, content, data, information, records and files
      that: (1) you generate, load, transmit to or enter into the Application;
      or (2) we collect from your local computer system or from third-parties
      with your permission (other than otherwise stated in the Terms and
      Conditions of Use or Privacy Policy), and (in each case) including all
      results from processing such data, including compilations, and derivative
      works thereof solely for the purpose of: (A) providing the Application
      Services; (B) complying with applicable law; and (C) Brand Ripplr&rsquo;s
      reasonable audit and data retention policies. We may also disclose
      aggregated user statistics in order to describe our services to current
      and prospective business partners, and to other third parties for other
      lawful purposes.
    </p>
    <p>
      Certain content may include or be based on data, information or content
      from Event Initiators or Influencers or other independent third party
      content providers (&ldquo;Third Party Content&rdquo;). Brand Ripplr has
      not verified the accuracy of, and will not be responsible for any errors
      or omissions in, any Third Party Content provided through the Application.
      Except as set forth in these Terms and Conditions of Use, you are granted
      no licenses or rights in or to any content, or any IPR therein or related
      thereto.
    </p>
    <p>&nbsp;</p>
    <p><strong>Event Initiator License</strong>&nbsp;</p>
    <p>
      Subject to these Terms and Conditions of Use, you grant to the Event
      Initiators an irrevocable and unconditional right to repost your published
      content (that you have linked to the Application) on social media. The
      Event Initiators will credit you accordingly. For the avoidance of doubt,
      the Event Initiators are not permitted to use you or your published posts,
      for any advertising or other similar marketing campaigns to promote their
      business.
    </p>
    <p>&nbsp;</p>
    <p><strong>Influencer License </strong><strong>&nbsp;</strong></p>
    <p>
      You agree that you are solely responsible for obtaining and holding the
      necessary up-to-date National Media Council or any other applicable
      licenses, permits or approvals that may be required by law to enable you
      to operate as an influencer and to create and post promotional online
      media content.
    </p>
    <p>&nbsp;</p>
    <p><strong>Content</strong></p>
    <p>
      <strong>Content must be:</strong> Accurate (where stating facts).
      Genuinely held (where stating opinions). Fair and legal.
    </p>
    <p><strong>Contributions must not:</strong></p>
    <ul>
      <li>
        contain any material which is defamatory, obscene, offensive, hateful or
        inflammatory or promotes sexually explicit material, violence or
        discrimination based on race, sex, religion, nationality, disability,
        sexual orientation or age;
      </li>
      <li>
        infringe any copyright, trademark or other intellectual property rights
        of any other person;
      </li>
      <li>
        be likely to deceive any person or made in breach of any legal duty owed
        to a third party, such as a contractual duty or a duty of confidence;
      </li>
      <li>
        be threatening, abusive or likely to harass or embarrass any other
        person or invade a person&rsquo;s privacy;
      </li>
      <li>
        be used to impersonate any person, or to misrepresent your identity or
        affiliation with any person;
      </li>
      <li>
        give the impression that they emanate from us, if this is not the case;
        and
      </li>
      <li>advocate, promote or assist any unlawful act.</li>
    </ul>
    <p>&nbsp;</p>
    <p><strong>Term and Termination</strong>&nbsp;</p>
    <p>
      The Terms and Conditions of Use and Privacy Policy (as set out below) are
      entered into as at the date you first download and install the
      Application.
    </p>
    <p>
      We may suspend, restrict, withdraw, terminate or amend your access to and
      use of the Application, or Application Services or any other services that
      we provide to you, at our sole discretion, at any time and without notice
      to you. You may cancel your User Account at any time by sending an email
      to us at <a href="mailto:support@buzzbee.com">support&#64;buzzbee.com</a>.
    </p>
    <p>
      Upon termination or expiration of these Terms and Conditions of Use for
      any reason: (1) all rights and subscriptions granted to you will
      terminate; (2) you will immediately delete the Application installed prior
      to termination; and (3) we may delete your User Account at any time, all
      provisions of these Terms and Conditions of Use which by their nature
      should survive will survive, including, without limitation, ownership
      provisions, warranty disclaimers, license grants, limitations of
      liability, and dispute resolution provisions.
    </p>
    <p>&nbsp;</p>
    <p><strong>Event Initiators and Events</strong></p>
    <p>
      The Application permits you to view the profiles of Event Initiators who
      invite you to an Event.
    </p>
    <p>
      You understand that the services are performed for the Event Initiator,
      and not Brand Ripplr, and that Brand Ripplr is not a party to and will be
      in no way responsible for: (1) your performance/ensuring your attendance
      at an Event(s); (2) the Event Initiator&rsquo;s
      conduct/treatment/relationship/communication with you before, during or
      after the Event; and (3) the Event itself.
    </p>
    <p>
      You agree to act in good faith when dealing and negotiating with an Event
      Initiator and performing your obligations for an Event.
    </p>
    <p>
      While you have the ability to rate an Event and the Event Initiators, you
      must not make or express any negative or disparaging remarks about the
      Event or the Event Initiator in your published posts or anywhere else. If
      this is not adhered to, you understand that you may not be invited to any
      future Event(s) or depending on the nature of the remarks, your account
      may be deactivated.
    </p>
    <p>
      If you do not attend an Event for which you had accepted an Invitation
      &ndash; you understand that the Event Initiator may give you a low rating
      or review which may impact your ability to participate in future Events.
    </p>
    <p>
      For transparency purposes, Event Initiators shall encourage you to
      disclose the sponsored relationship and/or the compensatory nature of the
      reviews of Event Initiator&rsquo;s products/services during an Event.
    </p>
    <p>&nbsp;</p>
    <p><strong>Disclaimer and Exclusion of Liability</strong>&nbsp;</p>
    <p>
      We take no responsibility and assume no liability, including but not
      limited to, any posts published by you on your social media platforms in
      connection with an Event.
    </p>
    <p>
      You are fully responsible for the posts you create and publish and you
      hereby warrant and represent that:
    </p>
    <ol>
      <li>you have all rights to the post(s) you publish;</li>
      <li>
        you have obtained all necessary licenses, permits or approvals that may
        apply to you in your jurisdiction;
      </li>
      <li>
        your published post(s): A) do not infringe the rights of any other
        person or body; and B) comply with all applicable laws, regulations,
        codes and standards, including without limitation all applicable
        advertising standards and regulations concerning sponsored or
        advertorial content that might be applicable to you in your
        jurisdiction. Such obligation includes but is not limited to a
        responsibility on you to ensure that any published post(s) include the
        necessary hash tag requirements that exist in respect of all applicable
        advertising standards and regulations;
      </li>
      <li>
        any published posts linked to the Application shall remain publicly
        accessible through your social media account(s) for a minimum of six (6)
        months. If you remove or delete the posts before six (6) months, you may
        be asked to refund the cost of/discount for the free service or goods
        that you received; and
      </li>
      <li>
        if you do not publish your post(s) within seven (7) days from the date
        of the Event you may be asked to the cost/discount for the free service
        or goods that you received.
      </li>
    </ol>
    <p><strong>&nbsp;</strong></p>
    <p><strong>Ownership </strong></p>
    <p>
      All published posts are owned by the Influencer(s). All designs, IPR,
      infrastructure graphics, pictures, illustrations, software, artwork,
      video, music, sound, names, words, titles, phrases, logos and marks of the
      Application, are owned by or licensed to Brand Ripplr and are protected by
      copyright, trade mark and other intellectual property laws.
    </p>
    <p>
      Brand Ripplr expressly reserves all rights in the Application and all
      materials provided by Brand Ripplr that are not specifically granted to
      you. You acknowledge that all right, title and interest in the
      Application, all materials provided by Brand Ripplr in connection with
      these Terms and Conditions of Use (including the Application Content), and
      any update, improvements, additions, adaptation, translation,
      customisation or derivative work thereof, and all intellectual property
      rights therein will remain with Brand Ripplr (or third party suppliers, if
      applicable), and that the Application and all materials provided by Brand
      Ripplr hereunder are licensed and not &ldquo;sold&rdquo; to you. Your
      right is limited to access the Application and Application Services in
      line with these Terms and Conditions of Use. All content and materials
      provided by Brand Ripplr on the Application are: &copy;2017 Brand Ripplr,
      LLC.
    </p>
    <p><strong>&nbsp;</strong></p>
    <p><strong>Interactive Services </strong></p>
    <p>
      We may from time to time provide interactive services through the
      Application, such as chat rooms, bulletin boards, personal messages,
      tagging to favourites, blogs and polling (together the &ldquo;interactive
      services&rdquo;). You must comply with the following standards when
      contributing any and all comments, blogs etc to any of the interactive
      services on the Application. We may review and edit any comments prior to
      publication on the Application and interactive services although we are
      not obliged to do so. Publication is entirely at our discretion.&nbsp;
      Where we do moderate an interactive service, you should contact us, should
      a concern or difficulty arise.
    </p>
    <p>
      Any communications that we receive from you in connection with our
      interactive services will be deemed non-confidential and may be viewed and
      used by others accessing the interactive services. You agree that we may
      re-publish any such communications for marketing or other purposes. You
      agree to compensate us against any claim, liability, cost, damage, or loss
      we may incur (including without limitation legal fees) as a result of any
      violation by you of the standards set out in these Terms and Conditions of
      Use.
    </p>
    <p>&nbsp;</p>
    <p><strong>PRIVACY POLICY </strong></p>
    <p>
      Brand Ripplr takes the private nature of personal information seriously.
      This Privacy Policy describes how we collect and what we do with the
      personal information we obtain when you access or use the Application
      Services and what controls you have. Please read this notice carefully and
      do not hesitate to let us know if you have any questions. You may send
      your questions to&nbsp;<a href="mailto:support@buzzbee.com"
        >support&#64;buzzbee.com</a
      >.&nbsp;
    </p>
    <p>
      By accessing or using the Application, you acknowledge and agree to the
      collection, use and storage by Brand Ripplr, and all of its predecessors,
      successors, parents, subsidiaries, affiliates, and past and present
      officers, directors, shareholders, investors, employees, agents,
      attorneys, representatives licensors and information providers, of the
      Information (as defined below) in accordance with the practices provided
      for in this Privacy Policy. Please note that we may update this Privacy
      Policy as we change or develop the Application (as defined in the Terms
      and Conditions of Use) or the Application Services. Your continued use of
      the Application constitutes your acceptance of the then-current privacy
      policy. For this reason, we recommend that you review the Privacy Policy
      from time to time to ensure that you understand and are willing to accept
      the current privacy practices of Brand Ripplr and that you carefully read
      and review any information before submitting the same to Brand Ripplr. If
      you do not accept the practices set out in this Privacy Policy you should
      not access or use the Application.
    </p>
    <p>
      When registering your User Account (as defined in the Terms and Conditions
      of Use), you subscribe by default to our newsletter containing promotional
      content for the Application Services. Should you no longer want to be
      subscribed to receive our newsletter, you can unsubscribe from the
      newsletter by clicking the &ldquo;Unsubscribe&rdquo; link at the bottom of
      any received newsletter.
    </p>
    <p>
      Please note that Event Initiators may have additional privacy policies or
      statements that govern their practices in collecting, storing, using and
      disclosing your personal information. Please read those additional privacy
      policies or statements carefully. You hereby represent and warrant to
      Brand Ripplr that you have familiarised yourself and agree with those
      privacy policies or statements imposed by any Event Initiator with whom
      you elect to deal through the Application.
    </p>
    <p>&nbsp;</p>
    <p><strong>THE INFORMATION WE MAY COLLECT FROM YOU</strong></p>
    <p>
      As is true of most applications, we gather certain information
      automatically and store it in log files or private databases.&nbsp;
    </p>
    <p>
      We may collect and process the following information (the
      &ldquo;Information&rdquo;):
    </p>
    <ul>
      <li>
        in connection with an account sign-in facility, your password and log-in
        details;
      </li>
      <li>
        your preferences, which help us provide you with more tailored
        recommendations;
      </li>
      <li>
        communications you send to us, for example to report a problem or to
        submit queries, concerns or comments regarding the Application;
      </li>
      <li>
        information from surveys that we may, from time to time, run for
        research purposes, if you choose to respond to them;
      </li>
      <li>
        details of the Events you accept/attend, the resources you access and
        any data you download;
      </li>
      <li>
        personal identification Information in a variety of ways, including, but
        not limited to, when you access the Application and in connection with
        other activities, services, features or resources we make available on
        our Application. You may be asked for, as appropriate, name, email
        address, mailing address and phone number. We will collect personal
        identification information from you only if you voluntarily submit such
        information to us. You can always refuse to supply personal
        identification information, except that it may prevent you from engaging
        in Events;
      </li>
      <li>
        non-personal data. For purposes of this Privacy Policy,
        &ldquo;Non-Personal Data&rdquo; means information that does not directly
        identify you. Additionally, Non-Personal Data means
        &ldquo;aggregate&rdquo; and &ldquo;de-personalised&rdquo; information,
        which is data we collect about the use of the Application Services, from
        which any personally identifiable data has been removed. We may use
        tools or third party analytical software to automatically collect and
        use certain Non-Personal Data that does not directly enable us to
        identify you. The types of Non-Personal Data we may collect and use
        include, but are not limited to: (i) device properties, including, but
        not limited to IP address, browser type, Internet Service Provider,
        referring/exit pages, operating system, date/time stamp, clickstream
        data, anonymously tracked on-page events, Media Access Control
        (&ldquo;MAC&rdquo;) address and unique device identifier or other device
        identifier (&ldquo;UDID&rdquo;); (ii) device software platform and
        firmware; (iii) mobile phone carrier; (iv) geographical data such as
        post code and coarse location; (v) other non-personal data as reasonably
        required by Brand Ripplr to enhance the Services; (vi) and Information
        obtained from your social networking profile, if you log-in via a social
        networking site.
      </li>
    </ul>
    <p>&nbsp;</p>
    <p>
      You are under no obligation to provide any Information. However, if you
      should choose to withhold requested Information, you may not be able to
      access or utilise the Application Services.
    </p>
    <p>&nbsp;</p>
    <p><strong>HOW WE USE YOUR INFORMATION</strong></p>
    <p>We will use the Information to:</p>
    <p>&nbsp;</p>
    <ul>
      <li>
        help us to build a profile of your preferences so we can offer you
        recommendations, goods and services you are interested in;
      </li>
      <li>administer your account with us;</li>
      <li>contact you by email when necessary;</li>
      <li>
        improve the layout and/or content of the pages on the Application and
        customise them for users;
      </li>
      <li>carry out research on our users&rsquo; demographics;</li>
      <li>disclose to lawful authorities when required to do so by law;</li>
      <li>send you newsletters and recommendations; and</li>
      <li>enhance your user experience in general.</li>
    </ul>
    <p>&nbsp;</p>
    <p>
      We may also occasionally contact you to obtain feedback on a specific
      Application Service or suggest social connections.&nbsp;
    </p>
    <p>
      We may combine the Information you submit under your account with
      information from other Brand Ripplr services or Third Parties Services (as
      defined below) in order to provide you with a better experience and to
      improve the quality of our Application Services. For certain Application
      Services, we will give you the opportunity to opt out of combining such
      information.
    </p>
    <p>
      We do not sell, trade, or rent your personal identification information to
      others. We may share generic aggregated demographic information not linked
      to any personal identification information regarding visitors and users
      with our business partners, trusted affiliates and advertisers for the
      purposes outlined in this Privacy Policy or our Terms and Conditions of
      Use.
    </p>
    <p>&nbsp;</p>
    <p><strong>No Unlawful or Prohibited Use</strong></p>
    <p>
      You shall not, without Brand Ripplr&rsquo;s prior written permission, use
      the Application for purposes other than the Permitted Use. Without
      limiting the generality of the foregoing, you shall not, and shall not
      permit anyone else to:
    </p>
    <ol style="list-style-type: lower-alpha">
      <li>
        &ldquo;frame&rdquo;, &ldquo;mirror&rdquo; or otherwise incorporate the
        Application or the published posts or any part thereof on any commercial
        or non-commercial website;
      </li>
      <li>
        access, monitor or copy any part of the Application or the published
        posts using any robot, spider, scraper or other automated means or any
        manual process for any purpose without our express written permission;
      </li>
      <li>
        violate the restrictions in any robot exclusion headers on the
        Application or published posts or bypass or circumvent other measures
        employed to prevent or limit access to the Application;
      </li>
      <li>
        modify or attempt to modify (or permit anyone else to modify or attempt
        to modify) the Application or any Events, including any modification for
        the purpose of disguising or changing any indications of the ownership
        or source of the Application or the Events;
      </li>
      <li>
        attempt to, assist, authorise or encourage others to circumvent, disable
        or defeat any of the security features or components, such as digital
        rights management software or encryption, that protect the Application;
      </li>
      <li>
        copy, reproduce, modify, distribute, transfer, sell, publish, broadcast,
        perform, transmit, publish, license or circulate in any form any part of
        the Application;
      </li>
      <li>
        create derivative works based on the Application or the published
        content, in whole or in part, or to decompile, disassemble, reverse
        engineer or other exploit any part of the Application or the published
        content;
      </li>
      <li>
        use or access the Application in a manner that violates the rights
        (including, but not limited to intellectual property rights) of any
        third party; or
      </li>
      <li>
        upload to or transmit through the Application any information, images,
        text, data, media or other content that is offensive, pornographic,
        hateful, obscene, defamatory or violates any laws or regulations
        (including but not limited to criminal laws and regulations), in each
        case as determined by Brand Ripplr in its sole discretion,
      </li>
      <li>
        post, upload, publish, submit or transmit any published content that:
        <ul style="list-style-type: disc">
          <li>
            infringes, misappropriates or violates a third party&rsquo;s patent,
            copyright, trademark, trade secret, moral rights or other
            intellectual property rights, or rights of publicity or privacy;
          </li>
          <li>
            violates, or encourages any conduct that would violate, any
            applicable law or regulation or would give rise to civil liability;
          </li>
          <li>is fraudulent, false, misleading or deceptive;</li>
          <li>is defamatory, obscene, pornographic, vulgar or offensive;</li>
          <li>
            promotes discrimination, bigotry, racism, hatred, harassment or harm
            against any individual or group;
          </li>
          <li>
            is violent or threatening or promotes violence or actions that are
            threatening to any person or entity; or
          </li>
          <li>
            promotes illegal or harmful activities or substances, contains or
            promotes alcohol, tobacco or shisha or anything that is deemed
            harmful or illegal by applicable law or any competent regulatory
            authority.
          </li>
        </ul>
      </li>
    </ol>
    <p>&nbsp;</p>
    <p>
      You shall be solely liable for any damages resulting from any violation of
      the foregoing restrictions, or any other harm resulting from linking your
      published post(s) to the Application. Brand Ripplr may ask you to remove
      or delete any published post(s) relating to an Event from your social
      media accounts and you acknowledge that such published post(s) shall be
      removed or deleted by you within twenty-four (24) hours of the
      request.&nbsp;
    </p>
    <p>
      Brand Ripplr does not knowingly work with, collect any information or
      allow minors to download and install the Application. If you are below the
      age of sixteen (16) then please ask your parents permission
      before&nbsp;accessing or viewing the Application. We encourage parents and
      legal guardians to monitor their children&rsquo;s usage of applications
      and social media platforms and to help enforce our Privacy Policy by
      instructing their children never to provide personally identifiable
      information on the Application. If you have reason to believe that a child
      has tried to download and install or provide personally identifiable
      information to us through the Application, please contact us&nbsp;at
      <a href="mailto:support@buzzbee.com">support&#64;buzzbee.com</a>, and we will
      endeavour to delete that information from our databases.
    </p>
    <p>
      To be an Influencer you represent and warrant that you are above
      sixteen(16) years old. If we determine that you have misrepresented your
      age Brand Ripplr shall immediately terminate your User Account and the
      Application Services and any other services that we may provide from time
      to time.
    </p>
    <p>&nbsp;</p>
    <p><strong>Third Party Websites</strong></p>
    <p>
      The Application may provide links to third party websites. Brand Ripplr
      does not endorse the information contained on those web sites or guarantee
      their quality, accuracy, reliability, completeness, currency, timeliness,
      non-infringement, merchantability or fitness for any purpose. The content
      in any linked web site is not under Brand Ripplr&rsquo;s control, and if
      you choose to access any such website, you do so entirely at your own
      risk.
    </p>
    <p>
      Brand Ripplr may enable you to link your User Account with a valid account
      on a third party social networking, email or content service such as
      Facebook, YouTube, or Twitter, (such service, a &ldquo;Third-Party
      Service&rdquo; and each such account, a &ldquo;Third-Party Account&rdquo;)
      by allowing Brand Ripplr to access your Third-Party Account, as is
      permitted under the applicable terms and conditions that govern your use
      of each Third-Party Account. You represent that you are entitled to
      disclose your Third-Party Account login information to Brand Ripplr and/or
      grant Brand Ripplr access to your Third-Party Account (including, but not
      limited to, for use for the purposes described herein) without breach by
      you of any of the terms and conditions that govern your use of the
      applicable Third-Party Account and without obligating Brand Ripplr to pay
      any fees or making Brand Ripplr subject to any usage limitations imposed
      by such third-party service providers.
    </p>
    <p>
      By granting Brand Ripplr access to any Third-Party Accounts, you
      understand that Brand Ripplr may access, make available and store (if
      applicable) any information, data, text, software, music, sound,
      photographs, graphics, video, messages, tags and/or other materials
      accessible that you have provided to and/or stored in your Third-Party
      Account (&ldquo;TPS Content&rdquo;) so that it is available on and through
      the Brand Ripplr Application via your User Account. Depending on the
      Third-Party Accounts you choose and subject to the privacy settings that
      you have set in such Third-Party Accounts, personally identifiable
      information that you post to your Third-Party Accounts may be available on
      and through your User Account on the Brand Ripplr Application. You hereby
      authorise Brand Ripplr to retrieve information from, and submit
      information to, such Third Party Services at your request; and to provide
      services relating to any information we retrieve from those Third-Party
      Accounts. Please note that if a Third-Party Account or associated service
      becomes unavailable or Brand Ripplr&rsquo;s access to such Third-Party
      Account is terminated by the third-party service provider, then TPS
      Content will no longer be available on and through the Brand Ripplr
      materials. You have the ability to disable the connection between your
      User Account and your Third-Party Accounts at any time by accessing the
      &ldquo;Profile&rdquo; section. In most cases, we do not actually store the
      entirety of your TPS Content, but instead we collect and store the
      metadata associated with such TPS Content. You hereby authorised such
      collection and storage.
    </p>
    <p>
      Relationship with third-party services: Please note that your relationship
      with the third-party service providers associated with your third-party
      accounts is governed solely by your agreement(s) with such third-party
      service providers, and Brand Ripplr disclaims any liability for personally
      identifiable information that may be provided to it by such third-party
      service providers in violation of the privacy settings that you have set
      in such third-party accounts.
    </p>
    <p>
      Brand Ripplr makes no effort to review any TPS Content for any purpose,
      including but not limited to, for accuracy, legality or non-infringement,
      and Brand Ripplr is not responsible for any TPS Content. You should note
      that a Third-Party Service may change or amend its guidelines and our
      access to it at any time, and we cannot guarantee that our Services will
      always include a connection to such Third Party Service.
    </p>
    <p>
      We may hire other companies to perform certain business related functions
      such as for Application Services. Any information provided to our third
      party will be confidential and only be used for intended purposes. You
      must provide us with accurate, complete information and it is your
      responsibility to update and maintain changes to that information. We are
      entitled to rely on any information you provide to us.
    </p>
    <p>
      As we develop our business, we might sell or buy businesses or assets. In
      the event of a corporate sale, merger, reorganisation, dissolution or
      similar event, personal data may be part of the transferred assets.
    </p>
    <p>&nbsp;</p>
    <p><strong>Viruses</strong></p>
    <p>
      The installation and use of the Application is done at your own risk.
      Brand Ripplr cannot and does not guarantee or warrant that the Application
      is compatible with your mobile phone or computer system, or shall be free
      of viruses, worms, trojan horses or disabling devices or other code that
      manifests contaminating or destructive properties. You are responsible for
      implementing safeguards to protect the security and integrity of your
      mobile phone or computer system, and you are responsible for the entire
      cost of any service, repairs or connections of and to your mobile phone or
      computer system that may be necessary as a result of you installing the
      Application.&nbsp;
    </p>
    <p>&nbsp;</p>
    <p><strong>Communications and Confidentiality</strong></p>
    <p>
      Brand Ripplr does not guarantee the confidentiality of any and all
      communication made by you through the Application. Although Brand Ripplr
      generally adheres to the accepted industry practices in securing the
      transmission of data to, from and through the Application, you understand,
      agree and acknowledge that Brand Ripplr cannot and does not guarantee the
      security of data transmitted through our Application.
    </p>
    <p>
      You hereby acknowledge and agree that by using the Application Services
      you will be privy to confidential information.
    </p>
    <p>You undertake that you shall:</p>
    <ol>
      <li>
        keep the Confidential Information (as defined below) secret and
        confidential and shall take all necessary steps to preserve its
        confidentiality;
      </li>
      <li>
        not disclose or make available any Confidential Information to any
        person, except as permitted by these Terms and Conditions of Use and
        provided that it:
        <ol style="list-style-type: lower-alpha">
          <li>
            informs the permitted recipient of the confidential nature of the
            Confidential Information before it is disclosed; and
          </li>
          <li>
            procures that the permitted recipient shall, in relation to any
            Confidential Information disclosed to it, comply with these Terms
            and Conditions of Use as if it were party thereto; and
          </li>
        </ol>
      </li>
      <li>
        not use or exploit the Confidential Information in any way, except for
        the Permitted Use.
      </li>
    </ol>
    <p>
      &ldquo;Confidential Information&rdquo; means all confidential information
      (however recorded or preserved) that is disclosed or made available (in
      any form or by any method) by Brand Ripplr, the Event Initiator or any
      member of its group to you or any of your agents, the Application or in
      connection with an Event, including:
    </p>
    <ol>
      <li>
        the fact that discussions or negotiations are taking place concerning
        the Event and the published post and status of such discussions or
        negotiations;
      </li>
      <li>
        all confidential or proprietary information relating to the business or
        affairs, financial or trading position, assets, intellectual property
        rights, customers, clients, suppliers, employees, plans, operations,
        processes, products, intentions or market opportunities of Brand Ripplr
        or the Event Initiator or any member of its group;
      </li>
      <li>
        the know-how, designs, trade secrets, technical information or software
        of the&nbsp;Event Initiator or any member of its group; and
      </li>
      <li>
        any other information that is identified as being of a confidential or
        proprietary nature.
      </li>
    </ol>
    <p><strong>&nbsp;</strong></p>
    <p><strong>Marketing to you</strong>&nbsp;</p>
    <p>
      We may send you information we think you may find useful or which you have
      requested via the Application and/or (if you provide us with your e-mail
      address) by email, including information about the latest recommendations
      or features on Brand Ripplr, provided you have indicated that you do not
      object to being contacted for these purposes and we will always give you
      the option to opt-out of receiving further emails or push notifications by
      following the unsubscribe instructions on any communications sent to you.
      You can also exercise this right at any time by contacting us using the
      contact details.
    </p>
    <p>&nbsp;</p>
    <p><strong>COOKIES POLICY </strong><strong>&nbsp;</strong></p>
    <p>
      Cookies/Web server logs: Similar to other commercial websites, our website
      (that we use for tracking) utilises &ldquo;cookies&rdquo; or similar
      technologies and web server logs to collect and store information about
      how our website is used and for marketing and retargeting. Cookies are a
      feature of web browser software that allows web servers to recognise the
      computer used to access a website. Cookies are small pieces of data that
      are stored by a user&rsquo;s web browser on the user&rsquo;s hard drive.
      Information gathered through cookies and web server logs may include the
      date and time of visits, the pages viewed, time spent at our website, and
      the web sites visited just before and just after our website. This
      information is collected on an aggregate basis. None of this information
      is associated with you as an individual.
    </p>
    <p>
      You can, of course, disable cookies on your computer by indicating this in
      the preferences or options menus in your browser. However, it is possible
      that some parts of our website will not operate correctly if you disable
      cookies. You should consult with your browser&rsquo;s
      provider/manufacturer if you have any questions regarding disabling
      cookies.
    </p>
    <p>
      Third Party Services: We may use services hosted by third parties
      including but not limited to Google analytics, Inspeclet, AWS to assist in
      providing our services and to help us understand how our websites are
      used. These services may collect information sent by a browser as part of
      a web page request, including IP addresses or cookies. If these third
      party services collect information, they do so anonymously and in the
      aggregate to provide information helpful to us such as website trends,
      without identifying individual visitors. Please see &ldquo;Cookies&rdquo;
      in the section above for information on how you can control the use of
      cookies on your computer.
    </p>
    <p>&nbsp;</p>
    <p><strong>Disclaimer and limitation of liability </strong></p>
    <p>
      Each party hereby represents and warrants that: (1) it has the legal right
      and authority to enter into these Terms and Conditions of Use; (2) these
      Terms and Conditions of Use form a binding legal obligation on behalf of
      such party; and (3) it has the legal right and authority to perform its
      obligations under these Terms and Conditions of Use and to grant the
      rights and licenses described in these Terms and Conditions of Use.
    </p>
    <p>
      The Application and the Application Content are provided &ldquo;as
      is&rdquo; without warranty or condition of any kind. Use of the
      Application or the Application Content is at your own risk. Brand Ripplr
      does not make any representations, warranties or conditions about the
      quality, accuracy, reliability, completeness, currency, or timeliness of
      the platform or the content. The Application and the Application Content
      may include errors, omissions and inaccuracies, including, without
      limitation, pricing errors. Brand Ripplr does not assume any
      responsibility for any errors, omissions or inaccuracies in the platform
      or the content. In addition, Brand Ripplr expressly reserves the right to
      correct any pricing errors on the platform.
    </p>
    <p>
      Event and Event Initiator reviews displayed on the Application are
      intended as only general guidelines, and Brand Ripplr does not guarantee
      the accuracy of the reviews. Brand Ripplr makes no guarantees about the
      availability of specific businesses, events or types of events.&nbsp;
    </p>
    <p>
      To the fullest extent permitted by law, Brand Ripplr disclaims all
      warranties, representations and conditions of any kind with respect to the
      Application, the Application Content, the Application Services and any
      other content whether express, implied, statutory or collateral,
      including, without limitation, the implied warranties and conditions of
      merchantability, fitness for a particular purpose and non-infringement or
      that the platform or the content are or will be error-free or will operate
      without interruption. In no event will Brand Ripplr be liable, whether
      based on warranty, contract, tort, negligence, strict liability or any
      other legal theory, for any damages of any kind (including, without
      limitation, direct, indirect, incidental, consequential, special,
      exemplary or punitive damages, lost profits, loss of use, loss of data,
      personal injury, fines, fees, penalties or other liabilities), whether or
      not Brand Ripplr is advised of the possibility of such damages, resulting
      from or related to the use of, or the inability to make use of, the
      Application, the Application Content, the Application Services or any
      other published post or any agreement relating to an Event.&nbsp;
    </p>
    <p>
      Event Initiators and their businesses engaging your services through the
      Application are independent organisations and not contractors, agents or
      employees of Brand Ripplr. Brand Ripplr is not liable for the acts,
      errors, omissions, representations, warranties, breaches or negligence of
      any businesses or for any personal injuries, death, property damage, or
      other damages or expenses resulting therefrom and takes no responsibility
      whatsoever related to any Events or agreements relating to those Events.
    </p>
    <p>
      Some jurisdictions do not allow the exclusion or limitation of liability
      for consequential or incidental damages, so the above limitation may not
      apply to you. To the extent that the foregoing limitation does not apply,
      in no event will the total aggregate liability of Brand Ripplr in
      connection with or under these Terms and Conditions of Use, or your use
      of, or inability to make use of, the Application or the Application
      Content, exceed one hundred dollars (US$100) or the equivalent in local
      currency. For greater certainty, the existence of one or more claims under
      these Terms and Conditions of Use will not increase the maximum liability
      amount.
    </p>
    <p>
      The limitations above reflect the allocation of risk between the parties.
      The limitations specified in this section will survive and apply even if
      any limited remedy specified in these terms is found to have failed of its
      essential purpose.
    </p>
    <p>
      Limitations period. You and Brand Ripplr agree that any cause of action
      arising out of or related to these Terms and Conditions of Use or Privacy
      Policy, the Application or the Application Services must commence within
      one (1) year after the cause of action accrues or becomes known to Brand
      Ripplr. Otherwise, such cause of action is permanently barred.
    </p>
    <p>&nbsp;</p>
    <p><strong>Indemnification</strong></p>
    <p>
      You shall defend, indemnify and hold harmless Brand Ripplr and all of its
      officers, directors, employees and agents from and against any claims,
      causes of action, demands, recoveries, losses, damages, fines, penalties
      or other costs or expenses of any kind or nature including but not limited
      to reasonable legal and accounting fees, brought in connection with or as
      a result of: (a) your breach of any of your warranties, undertakings,
      representations or obligations under these Terms and Conditions of Use or
      any documents referenced herein; (b) your violation of any law (including
      without limitation any legal requirements or guidelines) or the rights of
      a third party (including, without limitation, intellectual property
      rights); (c) your use of the Application; (d) breach of or failure to
      perform under any agreement by you or by any third party acting on your
      behalf or with your permission with an Event Initiator; or (d) the use of
      any proprietary post, including without limitation design, video, music by
      you without approval from relevant third parties to do so or the use of
      any social media content created by you, or third parties on your behalf
      in connection with an Event.
    </p>
    <p>&nbsp;</p>
    <p><strong>Governing Law</strong></p>
    <p>
      These Terms and Conditions of Use and any action related thereto shall be
      governed by the laws of the United Arab Emirates as applicable in Dubai
      without regard to its conflict of laws provisions. These laws apply to
      your access to or use of the Application, notwithstanding your domicile,
      residency or physical location. The Application is intended for use only
      in jurisdictions it may lawfully be offered for use. Except as restricted
      by applicable law, you hereby consent to the exclusive jurisdiction and
      venue of the courts located in Dubai, United Arab Emirates in all disputes
      arising out of or relating to the use of the Application.
    </p>
    <p>&nbsp;</p>
    <p><strong>Entire Agreement, Waiver and Severability</strong>&nbsp;</p>
    <p>
      The Terms constitute the entire agreement between Brand Ripplr and you
      pertaining to the subject matter hereof and supersede all prior or
      contemporaneous communications and proposals, whether electronic, oral or
      written, between you and Brand Ripplr with respect to the Application. A
      printed version of these Terms and Conditions of Use and of any notice
      given in electronic form shall be admissible in judicial or administrative
      proceedings based upon or relating to these Terms and Conditions of Use to
      the same extent and subject to the same conditions as other business
      documents and records originally generated and maintained in printed form.
    </p>
    <p>
      Brand Ripplr&rsquo;s failure to insist upon or enforce strict performance
      of any provision of these Terms and Conditions of Use shall not be
      construed as a waiver of any provisions or right. The waiver of any such
      right or provision will be effective only if in writing and signed by a
      duly authorised representative of Brand Ripplr. Except as expressly set
      forth in these Terms and Conditions of Use, the exercise by either party
      of any of its remedies under these Terms and Conditions of Use will be
      without prejudice to its other remedies under these Terms and Conditions
      of Use or otherwise.&nbsp;
    </p>
    <p>
      If for any reason a court of competent jurisdiction finds any provision of
      these Terms and Conditions of Use invalid or unenforceable, that provision
      will be enforced to the maximum extent permissible and the other
      provisions of these Terms and Conditions of Use will remain in full force
      and effect.
    </p>
    <p>
      If any of the provisions contained in these Terms and Conditions of Use
      conflict with the terms of another agreement between the parties, then
      these Terms and Conditions of Use shall prevail.
    </p>
    <p>Date Created: October 2020&nbsp;</p>
  </div>
</div>

import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from "@angular/material/dialog";
import { FeatureDialogComponent } from './feature-dialog/feature-dialog.component';

@Component({
  selector: 'app-package',
  templateUrl: './package.component.html',
  styleUrls: ['./package.component.scss']
})
export class PackageComponent implements OnInit {

  constructor(
    private dialog: MatDialog,) { }

  @Input('package') package: any;

  ngOnInit(): void {
  }

  openFeatureDialog(feature: { description: any; }) {
    if(feature.description){
      const dialogRef = this.dialog.open(FeatureDialogComponent, {
        width: "320px",
        maxWidth: "95vw",
        data: {
          ...feature
        },
      });
    }
  }

}

/* src/app/subscription-packages/subscription-packages.component.scss */
.subscription-packages {
  margin: auto;
  padding: 35px 10px;
  border-radius: 10px;
}
.subscription-packages .subscription-packages-title {
  width: 100%;
  height: 45px;
  text-align: center;
  font-size: 22px;
  font-weight: 600;
}
.subscription-packages .subscription-packages-body {
  padding: 10px;
}
.subscription-packages .subscription-packages-body .packages-container-mobile {
  display: none;
}
.subscription-packages .subscription-packages-body .packages-container {
  display: flex;
  justify-content: space-around;
}
.subscription-packages .subscription-packages-body .packages-container .selected-package {
  background-color: #fbbe0d;
  border: 1px #fbbe0d solid;
}
.subscription-packages .subscription-packages-body .package {
  border-radius: 15px;
  padding: 25px 20px;
  border: 1px solid #dddddd;
  margin-bottom: 10px;
  margin: 10px 5px;
  box-shadow: 0px 0px 10px rgba(116, 89, 89, 0.1);
  position: relative;
  width: 100%;
}
.subscription-packages .subscription-packages-body .bespoken-package {
  border: 1px solid #dddddd;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px;
  font-size: 20px;
}
.subscription-packages .subscription-packages-body .bespoken-package .bespoken-package-description {
  font-size: 12px;
}
.subscription-packages .subscription-packages-body .bespoken-package .contactUs {
  color: white;
  text-decoration: none;
  display: flex;
  align-items: center;
  font-weight: 600;
  justify-content: space-between;
  background: #06d771;
  padding: 7px 15px;
  border-radius: 40px;
}
.subscription-packages .subscription-packages-body .bespoken-package .contactUs .whatsapp-us {
  width: 110px;
  font-size: 12px;
  text-align: center;
  font-weight: 700;
}
@media (max-width: 900px) {
  .packages-container-mobile {
    display: flex !important;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: fit-content;
    margin: auto;
  }
  .packages-container-mobile .packages-tabs-container {
    width: 100%;
    height: 40px;
    border: 1px solid #dddddd;
    box-sizing: border-box;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    display: flex;
    margin-top: 10px;
  }
  .packages-container-mobile .packages-tabs-container .package-tab {
    width: 33.3333333333%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    font-weight: bold;
  }
  .packages-container-mobile .packages-tabs-container .package-tab-selected {
    background-color: #fcbe0f;
  }
  .bespoken-package {
    height: 180px;
    max-width: 97%;
    min-width: 212px;
    padding: 17px 40px;
    margin: auto;
    margin-top: 10px;
    flex-direction: column;
    text-align: center;
  }
}
/*# sourceMappingURL=subscription-packages.component.css.map */

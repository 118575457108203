
<div class="features-container">
    <div *ngFor="let feature of package.features" class="features-list-item"
        [ngClass]="{'more-info': feature.description}"
        (click)="openFeatureDialog(feature)"
        >
        <img src="assets/check.png" class="features-list-item-icon " />
        {{feature.label}}
        <!-- <span class="tool-tip-text">{{feature.description}}</span> -->
    </div>
</div>
<div class="features">
    <h1>Explore Features</h1>
    <p>The BuzzBee platform enables you to create experiences for influencers who will promote your business for free:<br>
- Hand-pick your preferred influencers <br>
- Chat with them via the app<br>
- See all content linked back to your BuzzBee account<br>
- Track performance on the go<br>
<br>
Everything you need packed in one buzzin’ platform.
</p>
    <div class="feature">
        <div class="col">
            <img src="assets/features/database.svg">
            <h2>The region's largest influencer database</h2>
            <p>As the trusted partner of the world’s largest brands we have thousands of vetted influencer profiles that you can choose to collaborate with.</p>
        </div>
        <div class="col right">
            <img src="assets/features/database.png" />
        </div>
    </div>
    <div class="feature">
        <div class="col left">
            <img src="assets/features/frontend.png" />
        </div>
        <div class="col">
            <img src="assets/features/frontend.svg">
            <h2>End-to-end campaign management</h2>
            <p>Access everything you need to manage your campaigns on your BuzzBee dashboard including event scheduling, briefing and liaising with influencers.</p>
        </div>
        <div class="col right small-device">
            <img src="assets/features/frontend.png" />
        </div>
    </div>
    <div class="feature">
        <div class="col">
            <img src="assets/features/share.svg" style="height:40px">
            <h2>Reshare influencer content</h2>
            <p>The influencers you collaborate with will upload their images and stories to your BuzzBee account. Simply download and share it on your socials channels.</p>
        </div>
        <div class="col right">
            <img src="assets/features/share.png" />
        </div>
    </div>
    <div class="feature">
        <div class="col left">
            <img src="assets/features/influencer-approval.png" />
        </div>
        <div class="col">
            <img src="assets/features/influencer-approval.svg">
            <h2>Influencer Approval &amp; Audience Data</h2>
            <p>Review and approve all influencers by looking at their audience data to ensure they are aligned with your target audience. BuzzBee gives you direct access to the data of every influencer.</p>
        </div>
        <div class="col right small-device">
            <img src="assets/features/influencer-approval.png" />
        </div>
    </div>
    <div class="feature">
        <div class="col">
            <img src="assets/features/chart.svg">
            <h2>Performance Tracking</h2>
            <p>Track the influencer’s performance of your campaign in real-time on BuzzBee to analyze top performing influencers, top performing content, performance across tiers, categories and more. </p>
        </div>
        <div class="col right">
            <img src="assets/features/chart.png" />
        </div>
    </div>
    <div class="feature">
        <div class="col left">
            <img src="assets/features/chat.png" />
        </div>
        <div class="col">
            <img src="assets/features/chat.svg">
            <h2>Direct Chat</h2>
            <p>You can chat directly with each influencer and tell them all they need to know to get the most out of your experience. </p>
        </div>
        <div class="col right small-device">
            <img src="assets/features/chat.png" />
        </div>
    </div>
    <div class="feature">
        <div class="col">
            <img src="assets/features/voucher.svg">
            <h2>Voucher System</h2>
            <p>The BuzzBee dashboard provides a simple way to share a unique voucher code with your chosen influencers to redeem their experience online or upon arrival.</p>
        </div>
        <div class="col right">
            <img src="assets/features/voucher.png" />
        </div>
    </div>
    <div class="feature">
        <div class="col left">
            <img src="assets/features/rating.png" />
        </div>
        <div class="col">
            <img src="assets/features/rating.svg">
            <h2>Rating system</h2>
            <p>Rate the influencers that experienced your business. They will in return rate your experience to help you attract interest from top influencers.</p>
        </div>
        <div class="col right small-device">
            <img src="assets/features/rating.png" />
        </div>
    </div>
    <div class="feature">
        <div class="col">
            <img src="assets/features/brand.svg">
            <h2>Multi-Brand Management</h2>
            <p>If you have more than one brand or outlets, you can manage them all from your dashboard.</p>
        </div>
        <div class="col right">
            <img src="assets/features/brand.png" />
        </div>
    </div>
    <div class="feature">
        <div class="col left">
            <img src="assets/features/discount-campaigns-feature.png" />
        </div>
        <div class="col">
            <img src="assets/features/percent.png">
            <h2>Discount campaigns</h2>
            <p>You can offer discounts in exchange of social media content to all of our influencers.</p>
        </div>
        <div class="col right small-device">
            <img src="assets/features/discount-campaigns-feature.png" />
        </div>
    </div>
    <div style="
    margin: 30px auto 0px;
    max-width: 800px;
    box-shadow: 0px 0px 10px 0px #dddddd;
    border-radius: 20px;">
        <app-subscription-packages></app-subscription-packages>
    </div>

</div>
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cookies-consent',
  templateUrl: './cookies-consent.component.html',
  styleUrls: ['./cookies-consent.component.scss']
})
export class CookiesConsentComponent implements OnInit {

  showCookiesConsentBar = false;
  constructor() {
    this.showCookiesConsentBar = JSON.parse(localStorage.getItem('showedCookiesConsentBar') ?? "false") ?? false;
  }

  ngOnInit(): void {
  }

  closeCookiesConsent(cookiesAllowed: boolean) {
    localStorage.setItem('cookiesAllowed', JSON.stringify(cookiesAllowed));
    localStorage.setItem('showedCookiesConsentBar', JSON.stringify(true));
    this.showCookiesConsentBar = true;
  }

}

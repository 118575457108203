import * as i0 from '@angular/core';
import { InjectionToken, Optional, NgModule, makeEnvironmentProviders, NgZone, Injector } from '@angular/core';
import { ɵgetAllInstancesOf as _getAllInstancesOf, ɵgetDefaultInstanceOf as _getDefaultInstanceOf, VERSION, ɵAngularFireSchedulers as _AngularFireSchedulers, ɵAppCheckInstances as _AppCheckInstances, ɵzoneWrap as _zoneWrap } from '@angular/fire';
import { FirebaseApp, FirebaseApps } from '@angular/fire/app';
import { AuthInstances } from '@angular/fire/auth';
import { registerVersion } from 'firebase/app';
import { timer, from } from 'rxjs';
import { concatMap, distinct } from 'rxjs/operators';
import { httpsCallable as httpsCallable$1 } from 'rxfire/functions';
import { connectFunctionsEmulator as connectFunctionsEmulator$1, getFunctions as getFunctions$1, httpsCallable as httpsCallable$2, httpsCallableFromURL as httpsCallableFromURL$1 } from 'firebase/functions';
export * from 'firebase/functions';

class Functions {
    constructor(functions) {
        return functions;
    }
}
const FUNCTIONS_PROVIDER_NAME = 'functions';
class FunctionsInstances {
    constructor() {
        return _getAllInstancesOf(FUNCTIONS_PROVIDER_NAME);
    }
}
const functionInstance$ = timer(0, 300).pipe(concatMap(() => from(_getAllInstancesOf(FUNCTIONS_PROVIDER_NAME))), distinct());

const PROVIDED_FUNCTIONS_INSTANCES = new InjectionToken('angularfire2.functions-instances');
function defaultFunctionsInstanceFactory(provided, defaultApp) {
    const defaultAuth = _getDefaultInstanceOf(FUNCTIONS_PROVIDER_NAME, provided, defaultApp);
    return defaultAuth && new Functions(defaultAuth);
}
function functionsInstanceFactory(fn) {
    return (zone, injector) => {
        const functions = zone.runOutsideAngular(() => fn(injector));
        return new Functions(functions);
    };
}
const FUNCTIONS_INSTANCES_PROVIDER = {
    provide: FunctionsInstances,
    deps: [
        [new Optional(), PROVIDED_FUNCTIONS_INSTANCES],
    ]
};
const DEFAULT_FUNCTIONS_INSTANCE_PROVIDER = {
    provide: Functions,
    useFactory: defaultFunctionsInstanceFactory,
    deps: [
        [new Optional(), PROVIDED_FUNCTIONS_INSTANCES],
        FirebaseApp,
    ]
};
class FunctionsModule {
    constructor() {
        registerVersion('angularfire', VERSION.full, 'fn');
    }
    static ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "18.0.0", ngImport: i0, type: FunctionsModule, deps: [], target: i0.ɵɵFactoryTarget.NgModule });
    static ɵmod = i0.ɵɵngDeclareNgModule({ minVersion: "14.0.0", version: "18.0.0", ngImport: i0, type: FunctionsModule });
    static ɵinj = i0.ɵɵngDeclareInjector({ minVersion: "12.0.0", version: "18.0.0", ngImport: i0, type: FunctionsModule, providers: [
            DEFAULT_FUNCTIONS_INSTANCE_PROVIDER,
            FUNCTIONS_INSTANCES_PROVIDER,
        ] });
}
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "18.0.0", ngImport: i0, type: FunctionsModule, decorators: [{
            type: NgModule,
            args: [{
                    providers: [
                        DEFAULT_FUNCTIONS_INSTANCE_PROVIDER,
                        FUNCTIONS_INSTANCES_PROVIDER,
                    ]
                }]
        }], ctorParameters: () => [] });
function provideFunctions(fn, ...deps) {
    registerVersion('angularfire', VERSION.full, 'fn');
    return makeEnvironmentProviders([
        DEFAULT_FUNCTIONS_INSTANCE_PROVIDER,
        FUNCTIONS_INSTANCES_PROVIDER,
        {
            provide: PROVIDED_FUNCTIONS_INSTANCES,
            useFactory: functionsInstanceFactory(fn),
            multi: true,
            deps: [
                NgZone,
                Injector,
                _AngularFireSchedulers,
                FirebaseApps,
                // Defensively load Auth first, if provided
                [new Optional(), AuthInstances],
                [new Optional(), _AppCheckInstances],
                ...deps,
            ]
        }
    ]);
}

// DO NOT MODIFY, this file is autogenerated by tools/build.ts
const httpsCallableData = _zoneWrap(httpsCallable$1, true);

// DO NOT MODIFY, this file is autogenerated by tools/build.ts
const connectFunctionsEmulator = _zoneWrap(connectFunctionsEmulator$1, true);
const getFunctions = _zoneWrap(getFunctions$1, true);
const httpsCallable = _zoneWrap(httpsCallable$2, true);
const httpsCallableFromURL = _zoneWrap(httpsCallableFromURL$1, true);

/**
 * Generated bundle index. Do not edit.
 */

export { Functions, FunctionsInstances, FunctionsModule, connectFunctionsEmulator, functionInstance$, getFunctions, httpsCallable, httpsCallableData, httpsCallableFromURL, provideFunctions };


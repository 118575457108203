import { Injectable, ChangeDetectorRef } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { AngularFirestore } from '@angular/fire/compat/firestore';

const REDIRECT_COLLECTION: string = 'redirect';
const COMMONS_COLLECTION: string = 'commons';

@Injectable({
  providedIn: 'any',
 })
export class AppService { 
  year: number = new Date().getFullYear();
  title = 'BuzzBee';
  mobileQuery: MediaQueryList;
  public _mobileQueryListener: () => void;

  constructor(changeDetectorRef: ChangeDetectorRef, media: MediaMatcher, private afs: AngularFirestore) {

    // request get https://www.cloudflare.com/cdn-cgi/trace
    
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  saveRedirectData(rurl: string, invId: string) {
    this.afs.collection(REDIRECT_COLLECTION, ref => ref.where('invId', "==", invId)).snapshotChanges().subscribe(res => {
      if (res.length > 0) {
        console.log("Match found.");
      }
      else {
        console.log("Does not exist.");
      }
    });
  }

  async getSubscriptionPackages() {
    var res = await this.afs.collection(COMMONS_COLLECTION).doc("subscription_plan").get().toPromise();
    if (res) {
      var resData = new Map<string, unknown>(Object.entries(res.data() as { [s: string]: unknown }));
      var subscriptionPackages: unknown[] = [];
      resData.forEach((value,key)=>{
        subscriptionPackages.push(value);
      });
      subscriptionPackages.sort((a,b)=>(a as any).displayOrder - (b as any).displayOrder);
      return subscriptionPackages;
    }
    return [];
  }
}
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-influencer',
  templateUrl: './influencer.component.html',
  styleUrls: ['./influencer.component.scss']
})
export class InfluencerComponent implements OnInit {

  public constructor(private titleService: Title ) { }

  ngOnInit() {
    this.titleService.setTitle('BuzzBee For Influencers');
  }

}

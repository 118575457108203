<div class="subscription-packages-container">
  <div class="subscription-packages">
    <div class="subscription-packages-title">
      Our Services
    </div>
    <div class="subscription-packages-body">

      <div class="packages-container">
          <app-package class="package" [package]="package" *ngFor="let package of subscriptionPackagesToDisplay"></app-package>
      </div>

      <!-- <div class="packages-container-mobile">
        <div class="packages-tabs-container">
          <div class="package-tab" *ngFor="let package of subscriptionPackagesToDisplay" [class]="{'package-tab-selected':package.label == selectedPackage.label}" (click)="selectPackage(package)">
            {{package.label}}
          </div>
        </div>
        <app-package *ngIf="selectedPackage" class="package" [package]="selectedPackage"></app-package>
      </div> -->

      <div class="bespoken-package">
        <div>
          <span><b>Partner with top UAE influences and promote your brand</b></span>
        </div>
        <div>
          <a href="https://wa.me/971527976165" target="_blank" class="contactUs"><img width="20"
              src="assets/whatsapp-white.png" class="features-list-item-icon"> <div class="whatsapp-us">WhatsApp Us</div></a>
        </div>
      </div>

    </div>
  </div>

</div>
import {MediaMatcher} from '@angular/cdk/layout';
import { Component, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Title }     from '@angular/platform-browser';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl, Validators} from '@angular/forms';
import { Functions,httpsCallable } from '@angular/fire/functions';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, NavigationEnd } from '@angular/router';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy {
  showMenu = false;
  year: number = new Date().getFullYear();
  title = 'BuzzBee';
  mobileQuery: MediaQueryList;
  formData: UntypedFormGroup | undefined;
  isSubmitted: boolean | undefined;
  isSending: boolean | undefined;
 
  private _mobileQueryListener: () => void;

  toggleShowMenu() {
    if(this.showMenu) {
      this.showMenu = false
    } else {
      this.showMenu = true
    }
  }

  constructor(private builder: UntypedFormBuilder, changeDetectorRef: ChangeDetectorRef, media: MediaMatcher,  private func: Functions, private snackBar: MatSnackBar, private router: Router ) {
    this.mobileQuery = media.matchMedia('(max-width: 950px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnInit() {
    this.isSending = false;
    this.isSubmitted = false;
    this.formData = this.builder.group({
    email: new UntypedFormControl('', Validators.compose([Validators.required, Validators.email])),
    })
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0)
    });
  }
  onSubmit(formData: unknown) {
    this.isSending = true;
    const callable = httpsCallable(this.func,'subscribeNewsLetter');
    callable(formData).then(res => {
      this.snackBar.open('Thank you for subscribing to our newsletter'
        ,'',{
        duration: 5000
      });
      if (this.formData) {
        this.formData.reset();
      }
      this.isSubmitted = true;
      this.isSending = false;
    }).catch(res => {
      console.log(res);
      this.snackBar.open('Something went wrong, please try again later','',{
        duration: 5000
      });
      this.isSending = false;
    });
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }
}

import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { SwiperOptions } from 'swiper';
import { AppService } from '../app.services';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  public constructor(private titleService: Title) {
  }

  config: SwiperOptions = {
    pagination: { el: '.swiper-pagination', clickable: true },
    autoHeight: true,
    allowTouchMove: true,
    breakpoints: {
      1024: {
        slidesPerView: 3
      },
      500: {
        slidesPerView: 3
      },
      400: {
        slidesPerView: 1.2,
        initialSlide: 0,
        centeredSlides: true
      },
      300: {
        slidesPerView: 1.2,
        initialSlide: 0,
        centeredSlides: true
      },
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    loop: false
  };



  ngOnInit() {
    this.titleService.setTitle('BuzzBee - Create a buzzzzz around your brand');
  }


}

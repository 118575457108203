<div class="influencers">
<div class="header">
    <h2>BuzzBee For Influencers</h2>
    <div class="buzzin">Let's Get Buzzin'</div>
    <p>Download the BuzzBee Influencer App to receive freebies from restaurants, hotels, beauty salons, fitness and leisure facilities and many more.</p>
    <div class="signup">
        Sign Up
    </div>
    <div class="app-links">
        <a href="https://apps.apple.com/app/id1521231473" target="_blank"><img src="assets/ios.svg"></a>
        <a href="https://play.google.com/store/apps/details?id=com.buzzbee.influencers" target="_blank"><img src="assets/android.svg"></a>
    </div>
</div>
<div class="content">
    <h4>How does it work?</h4>
    <div class="steps">
        <div class="step">
            <img src="assets/step1.png">
            <p>Download the BuzzBee App to receive free experiences from hotels, restaurants, attractions, beauty salons and more!</p>
        </div>
        <div class="step">
            <img src="assets/step2.png">
            <p>Pick the brands and experiences that match your interests.</p>
        </div>
        <div class="step">
            <img src="assets/step3.png">
            <p>Enjoy the experience, create buzzin’ content and share it with your followers</p>
        </div>
    </div>
    <div class="wishs-background">
    <h4>Everything you could wish for in one Buzzin’ app</h4>
    <div class="wishs">
        <div class="wish">
            <img src="assets/wish-1.png">
        </div>
        <div class="wish">
            <img src="assets/wish-2.png">
        </div>
        <div class="wish">
            <img src="assets/wish-3.png">
        </div>
    </div>
    </div>
    <div class="who-join">
    <h4>Who can join?</h4>
    <p>If you are passionate about new experiences and creating content then BuzzBee is the honey pot for you. You will need:</p>
    <ul>
        <li>-&nbsp;&nbsp;Minimum 3,000 followers on Instagram or TikTok</li>
        <li>-&nbsp;&nbsp;Portfolio of enriching and engaging content</li>
    </ul>
    <p>The App is free – simply download and apply to join our exclusive community of creators.</p>
    </div>
</div>
</div>
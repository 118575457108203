/* src/app/subscription-packages/package/package.component.scss */
.most-popular {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  background: #FFEAB0;
  height: 40px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #AD8000;
  font-size: 16px;
  border-radius: 15px 15px 0px 0px;
}
.label-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100px;
  width: 170px;
  margin: auto;
}
.label {
  font-size: 22px;
  text-align: center;
  font-weight: bold;
}
.suitable-for {
  font-size: 14px;
  text-align: center;
}
.package-no-influencers {
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 27px;
  text-align: center;
  letter-spacing: 0.01em;
}
.package-no-influencers .most-popular-package {
  font-size: 10px;
  background: #cf00ff;
  color: white;
  padding: 1px 10px;
  border-radius: 100px;
}
.package-pricing {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  margin: 25px 0px;
}
.package-pricing .start-at {
  font-size: 14px;
  color: #636363;
}
.package-pricing .billed-monthly {
  font-size: 14px;
  color: #636363;
}
.package-pricing .package-amount {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 7.5px;
}
.package-pricing .package-amount .discount {
  font-size: 10px;
  color: red;
  text-decoration: line-through;
  line-height: 9px;
}
.package-pricing .package-amount .net-amount {
  font-size: 21px;
  font-weight: 700;
  line-height: 41px;
}
.package-pricing .package-duration {
  font-size: 11px;
  font-weight: 100;
}
.start-your-campaign-bottom {
  background-color: #fcbe0f;
  font-weight: 600;
  border-radius: 50px;
  color: black;
  font-size: 16px;
  height: 50px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0px;
  width: 100%;
}
.cancel-anytime {
  text-align: center;
  margin-bottom: 20px;
  font-weight: 400;
  color: #636363;
  font-size: 14px;
}
.features-container {
  width: 100%;
  display: inline-flex;
  flex-direction: column;
}
.features-container .features-title {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;
}
.features-container .features-list-item {
  margin: 5px 0;
  display: flex;
  align-items: start;
  font-size: 14px;
}
.features-container .features-list-item .features-list-item-icon {
  margin-right: 5px;
  width: 20px;
}
.features-container .more-info {
  text-decoration: underline;
  cursor: pointer;
  position: relative;
}
/*# sourceMappingURL=package.component.css.map */

<div class="inner-page">
  <div class="header">
    <h2>Terms of Use</h2>
    <strong>Client</strong>
  </div>
  <div class="content">
    <p><strong>BACKGROUND </strong></p>
    <p><strong>&nbsp;</strong></p>
    <p><strong>What is BuzzBee?&nbsp;</strong>&nbsp;</p>
    <p>BuzzBee is a service accessible through a website application available to businesses and influencers, which
      connect businesses with local influencers in the same area. The businesses can offer free or discounted services
      and goods to their chosen influencers in exchange for promotion of their business on social media. BuzzBee is
      owned and operated by Brand Ripplr FZ-LLC, a free zone limited liability company established in Creative City,
      Media Free Zone, Fujairah under License number 11151/2017, Fujairah, United Arab Emirates (&ldquo;Brand
      Ripplr&rdquo;, &ldquo;us&rdquo;, &ldquo;we&rdquo;, &ldquo;our&rdquo;).</p>
    <p>&nbsp;</p>
    <p><strong>BuzzBee client website application</strong></p>
    <p>The client application is a website application (the &ldquo;Application&rdquo;) for business owners/businesses
      (each known as an &ldquo;Event Initiator&rdquo; and together the &ldquo;Event Initiators&rdquo;) who wish to
      utilise influencers (each an &ldquo;Influencer&rdquo; and together the &ldquo;Influencers&rdquo;) to promote or
      raise awareness of their business/brand.</p>
    <p>The Application facilitates the matching of Event Initiators with Influencers registered to the Application. The
      Event Initiator invites the Influencer(s) to visit their business premises or attend a promotional event that they
      are hosting (the &ldquo;Event&rdquo;) and offers them free or discounted goods or services in exchange for
      promotion through the creation and publication of posts on the Influencers social media accounts. Any services
      provided in connection with the Application are known as the &ldquo;Subscription Services&rdquo; (defined below).
      The term &ldquo;you&rdquo; shall refer to an Event Initiator as the context may require.</p>
    <p>&nbsp;</p>
    <p><strong>How do I sign up?</strong></p>
    <p>You can download and install the Application and sign up for a user account (the &ldquo;User Account&rdquo;) in
      order to access and use the Application and Subscription Services. You must: (1) keep your User Account details
      secure; (2) ensure that you do not share your User Account with anyone else; and (3) not collect or harvest any
      personal data of any other user of the Application, including account names. You acknowledge and agree that the
      User Account is created and used for the sole purpose of running and managing legitimate Events and utilising the
      Subscription Services.</p>
    <p>&nbsp;</p>
    <p><strong>Subscription Services</strong></p>
    <p>Through the Application you can avail of the following subscription services (the &ldquo;Subscription
      Services&rdquo;): (1) access BuzzBee&rsquo;s extensive database of influencers; (2) review influencer audience
      data and analytics and select which Influencer(s) you wish to invite to your Event; (3) provide information about
      the Event to the selected Influencer(s); (4) respond to any clarification questions from the Influencer(s) about
      the Event; (5) communicate with the Influencer(s); and (6) rate and review the Influencer(s) following the Event
      (the &ldquo;Permitted Use&rdquo;). By creating an Event, you acknowledge that the Event is subject to these Terms
      and Conditions of Use. Any Event, not consistent with the Terms and Conditions of Use may be rendered void at our
      sole discretion.</p>
    <p>Influencers have the ability to rate and review Events and Event Initiators which may impact the future
      acceptance of invitations by Influencers for other Events. The higher the rating for an Event Initiator, the
      greater the buzz around the business and the more other Influencers will want to attend and promote future
      Events.&nbsp;</p>
    <p>You agree that you are solely responsible for all communication between you and the Influencers through the
      Application or otherwise. Your election to share data and information about the business with Influencers, or
      notification to Influencers about an Event will serve as your affirmative &ldquo;opt in&rdquo; to the disclosure
      by Brand Ripplr of data and information about the business to the Influencer.</p>
    <p>Further details of the Subscription Terms (defined below) are set out below.</p>
    <p>&nbsp;</p>
    <p><strong>What are the next steps?</strong></p>
    <p>When you wish to promote a certain product or service, you will create and upload a brief for the Event and an
      invitation which is sent to your selected Influencers, through the Application. The invitation will include
      details of the Event. If your selected Influencers accept the invitation, they will attend at the allocated date,
      time and location of the Event. The Event will be solely arranged, managed and executed by the Event Initiator.
      The Influencer(s) will produce a post(s) that will promote the Event in line with the brief for the Event and
      publish the post(s) on their social media accounts within seven (7) days from the date of the Event.&nbsp; The
      Influencer(s) are not required to submit their post(s) for pre-approval by Brand Ripplr and/or the Event
      Initiator. The Influencer(s) will link their published post(s) with the Application. The Event Initiator will have
      the ability to repost the Influencer posts and may credit the Influencers when they do so.</p>
    <p><strong>&nbsp;</strong></p>
    <p><strong>TERMS AND CONDITIONS OF USE&nbsp;</strong>&nbsp;</p>
    <p>The following terms and conditions of use, or any future amendment thereof (the &ldquo;Terms and Conditions of
      Use&rdquo;) govern the access to and use of the Application. These Terms and Conditions of Use (together with the
      Subscription Terms, our Privacy and Cookies Policies (as set out below) together the &ldquo;Terms&rdquo;)) form an
      agreement between Brand Ripplr (&ldquo;Brand Ripplr&rdquo;, &ldquo;us&rdquo;, &ldquo;we&rdquo;, &ldquo;our&rdquo;)
      and you and shall be the sole agreement between us. These Terms are not intended to give rights to anyone except
      you and us.&nbsp;</p>
    <p><strong>&nbsp;</strong></p>
    <p><strong>User Account and the Application</strong></p>
    <p>Please read these Terms and Conditions of Use carefully before you download, access, browse or use the
      Application and/or Subscription Services. By downloading, accessing, browsing, creating or utilising a User
      Account, the Application (the term &ldquo;use&rdquo; when used herein in respect of the Application shall mean
      access or use, and using shall have a corresponding meaning) or underlying services you are accepting and agreeing
      to the Terms and Conditions of Use, as they may be amended from time to time. If you are not willing to be bound
      by these Terms and Conditions of Use, you should not download, access, browse or use the Application or create a
      User Account.</p>
    <p>By creating a User Account and accessing or using the Application either on behalf of yourself or another person
      if you act as an agent or on behalf of a corporate entity you represent and warrant that you have the authority to
      bind such person or entity to these Terms and Conditions of Use You are solely responsible for ensuring that the
      Terms and Conditions of Use are in compliance with applicable laws, rules and regulations applicable to you.</p>
    <p>By creating a User Account and accessing or using the Application, you represent, warrant and covenant that you
      provide Brand Ripplr accurate, truthful, complete and non-misleading information. It is your obligation to keep
      your registration information accurate and up to date. Failure to do so shall constitute a breach of these Terms
      and Conditions of Use, which may result in immediate cancellation of your User Account. Brand Ripplr reserves the
      right to disable any User Account issued to you at any time in Brand Ripplr&rsquo;s sole discretion. If Brand
      Ripplr disables access to a User Account issued to you, you may be prevented from accessing the Application, your
      account details or any Events that are associated with your account.</p>
    <p>You are responsible for safeguarding the password that you use to access the User Account and for any activities
      or actions under your password, whether your password is with our service or a third-party service. You are fully
      responsible for all liabilities and damages incurred through the use of your User Account (whether lawful or
      unlawful) and that any transactions completed through any User Account will be deemed to have been lawfully
      completed by you. You agree not to disclose your password to any third party. You must notify us immediately upon
      becoming aware of any breach of security or unauthorised use of your User Account. Failure to do so will
      constitute a material breach of these Terms and Conditions of Use.</p>
    <p>&nbsp;</p>
    <p><strong>Changes to the Terms and Conditionsof Use and the Application</strong></p>
    <p>Except where prohibited by applicable law, we reserve the right to amend these Terms and Conditions of Use at any
      time without notice. We recommend that you review these Terms and Conditions of Use periodically as they are
      binding upon you/your business. Your continued access to or use of the Application after any changes to these
      Terms and Conditions of Use indicates your acceptance of such changes. It is your responsibility to review these
      Terms and Conditions of Use regularly.</p>
    <p>We reserve the right to change, modify, substitute, suspend or remove any of the Subscription Services on the
      Application or information, material or content (including,&nbsp;but not limited to, features of the Application,
      Event Initiator profiles and details of the Events etc) all designs, IPR, infrastructure graphics, pictures,
      illustrations, software, artwork, video, music, sound, names, words, titles, phrases, logos and marks displayed or
      provided on or through the Application (the &ldquo;Application Content&rdquo;) at any time, and from time to time,
      without notice.&nbsp;</p>
    <p>Your access to the Application and/or the Subscription Services may also be occasionally restricted to allow for
      repairs, maintenance or the introduction of new facilities or services. We will attempt to restore such access as
      soon as we reasonably can.&nbsp;</p>
    <p>If you encounter any errors or omissions with the Application, please notify us by emailing&nbsp;<a
        href="mailto:support@buzzbee.com"><span>support&#64;buzzbee.com</span></a>&nbsp;as soon as possible. We
      cannot confirm that the Application and the Subscription Services will be free from faults nor can we accept
      liability for any such faults, errors or omissions (please refer to the Limitation of Liability paragraph below).
    </p>
    <p>&nbsp;</p>
    <p><strong>Use of the Application</strong></p>
    <p>As a condition of your use of the Application, you warrant, represent and covenant that: (a) you are sixteen (16)
      years of age or older; (b) you possess the legal authority to create a binding legal obligation; (c) you shall use
      the Application in accordance with these Terms and Conditions of Use; and (d) all information supplied by you on
      the Application is true, accurate, current, not misleading and complete. If you are between the ages of sixteen
      (16) and eighteen (18) years old, parental consent will be required before you can use the Application</p>
    <p>We reserve the right, at our sole discretion to: (a) reject, block and/or deny access to anyone to the
      Application or the Subscription Services and/or to edit or remove any material which in our reasonable opinion may
      give rise to a breach of these Terms and Conditions of Use; and (b) reject or block an Event from being promoted
      through the Application, for any reason whatsoever including but not limited to, if the Event:</p>
    <ol>
      <li>contains any material which is defamatory, obscene, offensive, hateful or inflammatory or promotes sexually
        explicit material, violence or discrimination based on race, sex, religion, nationality, disability, sexual
        orientation or age;</li>
      <li>infringes any copyright, trademark or other intellectual property rights of any other person;</li>
      <li>is likely to deceive any person or made in breach of any legal duty owed to a third party, such as a
        contractual duty or a duty of confidence;</li>
      <li>is or may be threatening, abusive or likely to harass or embarrass any other person or invade a person&rsquo;s
        privacy;</li>
      <li>may be used to impersonate any person, or to misrepresent your identity or affiliation with any person;</li>
      <li>gives the impression that they emanate from us, if this is not the case; or</li>
    </ol>
    <p>advocates, promotes or assists any unlawful act. You shall cease to access or use the Application immediately
      upon request by us.</p>
    <p>&nbsp;</p>
    <p><strong>Your Obligations</strong>&nbsp;</p>
    <p>You are responsible for ensuring that your Event is as stated in the description of the Event on the Application
      and that you honour the booking of Influencers you have selected for your Event.</p>
    <p>Without limitation, you undertake to not to use or permit anyone else to use the Subscription Services or
      Application to:</p>
    <ol>
      <li>upload inappropriate content;</li>
      <li>upload any content that is not your own;</li>
      <li>engage in acts that would be considered contrary to the applicable law; and</li>
      <li>use for a purpose other than which we have designed them or intended for them to be used.</li>
    </ol>
    <p>&nbsp;</p>
    <p><strong>Brand Ripplr License</strong></p>
    <p>Subject to these Terms and Conditions of Use, Brand Ripplr grants you a personal, revocable, non-exclusive and
      non-transferable, non-sub licensable license during the Term to permit you to access and use the Application in
      accordance with these Terms and Conditions of Use. The Application, and the databases, software, hardware and
      other technology used by or on behalf of Brand Ripplr to operate the Application, and the structure, organisation,
      and underlying data, information and software code thereof, constitute valuable trade secrets of Brand Ripplr. The
      Application and any portion thereof may not be reproduced, duplicated, copied, reverse engineered, downloaded,
      sold, resold, visited, or otherwise exploited for any commercial purpose without the express written consent of
      Brand Ripplr. You may not frame or utilise framing techniques to enclose any trademark, logo or other proprietary
      information (including images, text, page layout and form) of Brand Ripplr without our express written consent.
      You may not use any meta tags or any other &ldquo;hidden text&rdquo; utilising Brand Ripplr&rsquo;s name or
      trademarks without our express written consent. Any unauthorised use terminates the license granted by Brand
      Ripplr.&nbsp;</p>
    <p>Subject to these Terms and Conditions of Use, you grant to Brand Ripplr, a perpetual, transferrable, irrevocable,
      royalty-free, fully paid-up, worldwide and fully sub licensable license to access, collect, store and use any
      published posts, content, data, information, records and files that: (1) you generate, load, transmit to or enter
      into the Application; or (2) we collect from your local computer system or from third-parties with your permission
      (other than otherwise stated in the Terms and Conditions of Use or Privacy Policy), and (in each case) including
      all results from processing such data, including compilations, and derivative works thereof solely for the purpose
      of: (A) providing the Subscription Services; (B) complying with applicable law; and (C) Brand Ripplr&rsquo;s
      reasonable audit and data retention policies. We may also disclose aggregated user statistics in order to describe
      our services to current and prospective business partners, and to other third parties for other lawful purposes.
    </p>
    <p>Certain content may include or be based on data, information or content from Event Initiators or Influencers or
      other independent third party content providers (&ldquo;Third Party Content&rdquo;). Brand Ripplr has not verified
      the accuracy of, and will not be responsible for any errors or omissions in, any Third Party Content provided
      through the Application. Except as set forth in these Terms and Conditions of Use, you are granted no licenses or
      rights in or to any content, or any IPR therein or related thereto.</p>
    <p>&nbsp;</p>
    <p><strong>Event Licenses and Permissions</strong>&nbsp;</p>
    <p>You are solely responsible for and hereby warrant and represent that:</p>
    <ol>
      <li>you have obtained all necessary licenses, permits or approvals for your Event, that may apply in your
        jurisdiction; and</li>
      <li>your Event: A) does not infringe the rights of any other person or body; and B) complies with all applicable
        laws, regulations (including health and safety), codes and standards, including without limitation all
        applicable advertising standards and regulations concerning sponsored or advertorial content that might be
        applicable to you in your jurisdiction.</li>
    </ol>
    <p>&nbsp;</p>
    <p><strong>Influencer Licensing</strong></p>
    <p>Influencers are solely responsible for obtaining and holding the necessary up-to-date National Media Council or
      equivalent license or any other applicable licenses, permits or approvals that may be required by law to enable
      them to operate as an influencer and to create and post promotional online media content. Brand Ripplr takes no
      responsibility and assumes no liability for ensuring that the Influencers hold valid NMC, equivalent or other
      licenses if and when applicable.&nbsp;</p>
    <p>Content must not:</p>
    <ul>
      <li>contain any material which is defamatory, obscene, offensive, hateful or inflammatory or promotes sexually
        explicit material, violence or discrimination based on race, sex, religion, nationality, disability, sexual
        orientation or age;</li>
      <li>infringe any copyright, trademark or other intellectual property rights of any other person;</li>
      <li>be likely to deceive any person or made in breach of any legal duty owed to a third party, such as a
        contractual duty or a duty of confidence;</li>
      <li>be threatening, abusive or likely to harass or embarrass any other person or invade a person&rsquo;s privacy;
      </li>
      <li>be used to impersonate any person, or to misrepresent your identity or affiliation with any person;</li>
      <li>give the impression that they emanate from us, if this is not the case; and</li>
      <li>advocate, promote or assist any unlawful act.</li>
    </ul>
    <p><strong>&nbsp;</strong></p>
    <p><strong>Permission to repost content created by Influencers</strong>&nbsp;</p>
    <p>Subject to these Terms and Conditions of Use, you shall be granted an irrevocable and unconditional right by the
      Influencers to repost their published content on your social media accounts You may but are not obligated to tag
      the Influencers if you repost their published content. For the avoidance of doubt, you are not permitted to use
      the Influencers or their published posts, for any advertising, social media or other similar marketing campaigns
      to promote your business.</p>
    <p><strong>&nbsp;</strong></p>
    <p><strong>Term and Termination</strong></p>
    <p>The Terms are entered into as at the date you first download and install the Application.&nbsp;</p>
    <p>We may suspend, restrict, withdraw, terminate or amend your access to and use of the Application, or Subscription
      Services in accordance with the Subscription Terms below or if required at our sole discretion.</p>
    <p>Upon termination of your Subscription: (1) all rights and subscriptions granted to you will terminate; (2) you
      will immediately delete the Application installed prior to termination; and (3) we may delete your User Account at
      any time, all provisions of these Terms and Conditions of Use which by their nature should survive will survive,
      including, without limitation, ownership provisions, warranty disclaimers, license grants, limitations of
      liability, and dispute resolution provisions.&nbsp;</p>
    <p>The Application permits you to view the profiles of the Influencers that you can invite to your Event(s).</p>
    <p>You understand that the services being provided by Influencers to you, are solely for your benefit and not the
      benefit of Brand Ripplr, and that Brand Ripplr is not a party to and will be in no way responsible for: (1) your
      Influencer selection; (2) the acceptance of an invitation from you, for an Event, by the Influencers; (3) ensuring
      Influencer attendance at an Event(s); (4) the &lsquo;no-show&rsquo; of an Influencer at an Event; (5) the
      Influencer(s) conduct/treatment/relationship/communication with you before, during or after the Event; and (6) the
      performance or success of an Event.</p>
    <p>You agree to act in good faith when dealing and negotiating with an Influencer and performing your obligations
      for your Event.</p>
    <p>While you can rate and review an Influencer for their performance at your Event, you must not make or express any
      negative or disparaging remarks about the Influencer at any time. If this is not adhered to, you understand that
      this may deter other Influencers from wanting to work with you and/or depending on the nature of the remarks, your
      account may be deactivated.</p>
    <p>&nbsp;</p>
    <p><strong>Disclaimer and Exclusion of liability</strong></p>
    <p>You understand that the services being provided by Influencers to you, are solely for your benefit/the benefit of
      your business and not the benefit of Brand Ripplr, and that Brand Ripplr is not a party to and will in no way be
      responsible for or assume liability for the following:</p>
    <ol>
      <li>the selection of Influencers by you, for your Event(s);</li>
      <li>Influencer acceptance of invitations to your Event(s);</li>
      <li>the cancellation, rescheduling and non-timely attendance of Influencers selected by you for your Event(s);
      </li>
      <li>the management, performance or success of your Event(s);</li>
      <li>the quality and availability of the Influencers content created for your Event;</li>
      <li>any posts published by Influencers on their social media accounts in connection with your Event;</li>
      <li>where an Influencer uses your service or product/attends an Event but does not publish post a within seven (7)
        days from the date of your Event or at all;</li>
      <li>deletion of any published posts by Influencers about your Events; and</li>
      <li>the conduct/relationship/communication between you and the Influencers before, during or after the Event.</li>
    </ol>
    <p>You further acknowledge and agree as follows:</p>
    <ol>
      <li>audience data/analytics for the Influencer accessible through the Application should not be relied upon;</li>
      <li>to act in good faith when dealing and negotiating with an Influencer and performing your obligations for your
        Event; and</li>
      <li>while you can rate and review an Influencer for their performance at your Event, you must not make or express
        any negative or disparaging remarks about the Influencer at any time. If this is not adhered to, you understand
        that this may deter other Influencers from wanting to work with you and/or depending on the nature of the
        remarks, your account may be deactivated.</li>
    </ol>
    <p>Brand Ripplr shall not be liable for any acts or omissions caused by third parties or influencers and will not
      take any responsibility for losses or damages caused as a result.</p>
    <p>We do not warrant that the Application will always be accessible, uninterrupted, timely, secure, error free or
      free from computer bugs.</p>
    <p>While we may use reasonable efforts to include accurate and up-to-date information on the Application, we make no
      warranties or representations as to its accuracy, timeliness or completeness.</p>
    <p>&nbsp;</p>
    <p><strong>Indemnity</strong></p>
    <p>You agree to indemnify and keep us indemnified against any claim, action, suit or proceeding brought or
      threatened to be brought against us which is caused by or arising out of: (a) your use of the Subscription
      Services; or (b) any other party&rsquo;s use of the Subscription Services using your username, password and/or any
      identifier number allocated by us; and/or (c) your breach of any of these Terms and Conditions of Use and to pay
      us damages, costs and interest in connection with such claim, action, suit or proceeding.</p>
    <p><strong>&nbsp;</strong></p>
    <p><strong>Ownership</strong></p>
    <p>All published posts are owned by the Influencer(s). All designs, IPR, infrastructure graphics, pictures,
      illustrations, software, artwork, video, music, sound, names, words, titles, phrases, logos and marks of the
      Application, are owned by or licensed to Brand Ripplr and are protected by copyright, trademark and other
      intellectual property laws.</p>
    <p>Brand Ripplr expressly reserves all rights in the Application and all materials provided by Brand Ripplr that are
      not specifically granted to you. You acknowledge that all right, title and interest in the Application, all
      materials provided by Brand Ripplr in connection with these Terms and Conditions of Use (including the Application
      Content), and any update, improvements, additions, adaptation, translation, customisation or derivative work
      thereof, and all intellectual property rights therein will remain with Brand Ripplr (or third party suppliers, if
      applicable), and that the Application and all materials provided by Brand Ripplr hereunder are licensed and not
      &ldquo;sold&rdquo; to you. Your right is limited to access the Application and Subscription Services in line with
      these Terms and Conditions of Use. All content and materials provided by Brand Ripplr on the Application are:
      &copy;2017 Brand Ripplr, LLC.</p>
    <p><strong>&nbsp;</strong></p>
    <p><strong>Interactive Services&nbsp;</strong>&nbsp;</p>
    <p>We may from time to time provide interactive services through the Application, such as, chat rooms, bulletin
      boards, personal messages, tagging to favourites, blogs and polling (together the &ldquo;interactive
      services&rdquo;). You must comply with the below standards when contributing any and all comments, blogs etc to
      any of the interactive services on the Application. We may review and edit any comments prior to publication on
      the Application and interactive services although we are not obliged to do so. Publication is entirely at our
      discretion. Where we do moderate an interactive service, you should contact us, should a concern or difficulty
      arise.</p>
    <p>Any communications that we receive from you in connection with our interactive services will be deemed
      non-confidential and may be viewed and used by others accessing the interactive services. You agree that we may
      re-publish any such communications for marketing or other purposes. You agree to compensate us against any claim,
      liability, cost, damage, or loss we may incur (including without limitation legal fees) as a result of any
      violation by you of the standards set out in these Terms and Conditions of Use.</p>
    <p>&nbsp;</p>
    <p><strong>SUBSCRIPTION TERMS&nbsp;&nbsp;</strong></p>
    <p><strong>Plans</strong></p>
    <p>The Subscription Services may be offered through a &ldquo;Starter Plan&rdquo; (as may be amended from time to
      time). Other plans may be introduced and/or offered to you in due course. The terms of each plan will vary,
      depending on the subscription you sign up for.<strong>&nbsp;</strong></p>
    <p><strong>Registration and Payment&nbsp;&nbsp;</strong>&nbsp;</p>
    <p>In order to access and utilise the Subscription Services, you must download and install the Application and
      confirm your subscription by simply clicking on the allocated opt-in box &ndash; which means you expressly request
      that we commence your subscription.</p>
    <p>You will then be asked to select a subscription package (as outlined below) and pay the subscription fees
      (&ldquo;Subscription Fees&rdquo;) on a monthly or annual basis.&nbsp;&nbsp;You may make the payment by credit card
      or other payment method. As soon as we receive cleared payment in full of the relevant Subscription Fees your
      Subscription Services shall commence ("Commencement Date"). If there is an an issue with your credit card or other
      payment method when being charged &ndash; your account will be frozen until payment is made and any Event(s) shall
      be put on hold. Any disruption or loss incurred by you in connection with the non-payment of the Subscription Fees
      cannot be attributed to us.&nbsp;</p>
    <p>&nbsp;</p>
    <p><strong>Subscription Packages</strong></p>
    <p>&nbsp;</p>
    <p>The Subscription Fees are split into different packages based on the number of influencers you want to utilise
      for your Event (&ldquo;Subscription Packages&rdquo;).&nbsp;&nbsp;The Subscription Packages are available on the
      Application.</p>
    <p>&nbsp;</p>
    <p>You can:</p>
    <p>&nbsp;</p>
    <ol>
      <li>upgrade your Subscription Package at any time and the upgrade will take&nbsp;effect immediately. For example,
        if you are paying AED 600 for 8 influencers and want to upgrade to 16 influencers per month, as at the date you
        upgrade you will be charged in full the difference in the Subscription Fees which is AED 400. The number of
        influencers you can then access during this month will then increase to 16 influencers; and&nbsp;</li>
    </ol>
    <p>&nbsp;</p>
    <ol>
      <li>downgrade your Subscription Package at any time. This will take effect in your next billing cycle and you will
        not receive a refund for the current month. For example, if you are paying AED 1000 for 16 influencers and you
        want to downgrade to the AED 400 Package for 4 influencers per month - your Subscription Fees will change to AED
        400 the following month. You will not receive a refund for the current month.&nbsp;&nbsp;</li>
    </ol>
    <p>&nbsp;</p>
    <p><strong>Term and Termination of the Subscription Services&nbsp;&nbsp;</strong></p>
    <p>The &ldquo;Subscription Term&rdquo; shall begin on the Commencement Date and shall continue unless terminated in
      accordance with these Terms and Conditions of Use (&ldquo;Subscription Term&rdquo;). The payment details used for
      payment of the Subscription Fees for the Subscription Term shall be utilised for any renewal.</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p><strong>Termination by you&nbsp;</strong>&nbsp;</p>
    <p>You can terminate your Subscription Services at any time. If you cancel your Subscription Services, your
      Subscription will end at the end of that billing cycle. You will be able to utilise the Subscription Services
      until the end of that billing cycle. For example, if you cancel your Subscription on 1 May 2021 and your next
      billing date is 21 May 2021, you will be able to utilitise the Subscription Services until 21 May 2021 and you
      will not be charged after 21 May 2021. Where you want to terminate the Subscription Services, you can request that
      we deactivate your User Account by sending an email to us at&nbsp;<a
        href="mailto:support@buzzbee.com"><span>support&#64;buzzbee.com</span></a>. No refund shall be applicable (including
      but not limited to, in the
      event of an upfront payment of the Subscription Fees) where you terminate your Subscription Services.</p>
    <p>Upon termination, all licences granted shall immediately terminate; you shall make no further use of the
      Subscription Services and we shall cancel all access information, log-in details and passwords.</p>
    <p>&nbsp;</p>
    <p><strong>Use of the Subscription Services&nbsp;</strong></p>
    <p>You undertake that you shall:</p>
    <ul>
      <li>use all reasonable endeavours to prevent any unauthorised access to, or use of, the Subscription Services;
      </li>
      <li>notify us immediately if you believe there has been any breach of security (such as the disclosure, theft or
        unauthorised use of any username or password); and</li>
      <li>comply with the Terms and Conditions of Use.</li>
    </ul>
    <p>&nbsp;</p>
    <p><strong>Subscription Fees increase</strong></p>
    <p>We may review and increase the Subscription Fees. We shall give you written notice of any such increase. All
      amounts and fees stated or referred shall be payable in US dollars.&nbsp;&nbsp;</p>
    <p>&nbsp;</p>
    <p><strong>PRIVACY POLICY&nbsp;</strong>&nbsp;</p>
    <p>Brand Ripplr takes the private nature of personal information seriously. This Privacy Policy describes how we
      collect and what we do with the information we obtain when you access or use the Subscription Services and what
      controls you have. Please read this notice carefully and do not hesitate to let us know if you have any questions.
      You may send your questions to&nbsp;<a
        href="mailto:support@buzzbee.com"><span>support&#64;buzzbee.com</span></a>.&nbsp;</p>
    <p>By accessing or using the Application, you acknowledge and agree to the collection, use and storage by Brand
      Ripplr, and all of its predecessors, successors, parents, subsidiaries, affiliates, and past and present officers,
      directors, shareholders, investors, employees, agents, attorneys, representatives licensors and information
      providers, of the Information (as defined below) in accordance with the practices provided for in this Privacy
      Policy. Please note that we may update this Privacy Policy as we change or develop the Application (as defined in
      the Terms and Conditions of Use) or the Services. Your continued use of the Application constitutes your
      acceptance of the then-current privacy policy. For this reason, we recommend that you review the Privacy Policy
      from time to time to ensure that you understand and are willing to accept the current privacy practices of Brand
      Ripplr and that you carefully read and review any information before submitting the same to Brand Ripplr. If you
      do not accept the practices set out in this Privacy Policy you should not access or use the Application.</p>
    <p>When registering your User Account (as defined in the Terms and Conditions of Use), you subscribe by default to
      our newsletter containing promotional content for the Subscription Services. Should you no longer want to be
      subscribed to receive our newsletter, you can unsubscribe from the newsletter by clicking the
      &ldquo;Unsubscribe&rdquo; link at the bottom of any received newsletter.&nbsp;</p>
    <p>Please note that Event Initiators may have additional privacy policies or statements that govern their practices
      in collecting, storing, using and disclosing your personal information. Please read those additional privacy
      policies or statements carefully. You hereby represent and warrant to Brand Ripplr that you have familiarised
      yourself and agree with those privacy policies or statements imposed by any Event Initiator with whom you elect to
      deal through the Application.</p>
    <p>&nbsp;</p>
    <p><strong>THE INFORMATION WE MAY COLLECT FROM YOU</strong>&nbsp;</p>
    <p>As is true of most applications, we gather certain information automatically and store it in log files or private
      databases.</p>
    <p>We may collect and process the following information (the &ldquo;Information&rdquo;):</p>
    <ul>
      <li>in connection with an account sign-in facility, your password and log-in details;</li>
      <li>your preferences, which help us provide you with more tailored recommendations;</li>
      <li>communications you send to us, for example to report a problem or to submit queries, concerns or comments
        regarding the Application;</li>
      <li>information from surveys that we may, from time to time, run for research purposes, if you choose to respond
        to them;</li>
      <li>details of the Events you accept/attend, the resources you access and any data you download;</li>
      <li>personal identification information in a variety of ways, including, but not limited to, when you access the
        Application and in connection with other activities, services, features or resources we make available on our
        Application. You may be asked for, as appropriate, name, email address, mailing address and phone number. We
        will collect personal identification information from you only if you voluntarily submit such information to us.
        You can always refuse to supply personal identification information, except that it may prevent you from
        inviting Influencers to your Event;</li>
      <li>non-personal data. For purposes of this Privacy Policy, &ldquo;Non-Personal Data&rdquo; means information that
        does not directly identify you. Additionally, Non-Personal Data means &ldquo;aggregate&rdquo; and
        &ldquo;de-personalised&rdquo; information, which is data we collect about the use of the Subscription Services,
        from which any personally identifiable data has been removed. We may use tools or third party analytical
        software to automatically collect and use certain Non-Personal Data that does not directly enable us to identify
        you. The types of Non-Personal Data we may collect and use include, but are not limited to: (i) device
        properties, including, but not limited to IP address, browser type, Internet Service Provider, referring/exit
        pages, operating system, date/time stamp, clickstream data, anonymously tracked on-page events, Media Access
        Control (&ldquo;MAC&rdquo;) address and unique device identifier or other device identifier
        (&ldquo;UDID&rdquo;); (ii) device software platform and firmware; (iii) mobile phone carrier; (iv) geographical
        data such as post code and coarse location; (v) other non-personal data as reasonably required by Brand Ripplr
        to enhance the Services; (vi) and Information obtained from your social networking profile, if you log-in via a
        social networking site.&nbsp;</li>
    </ul>
    <p>You are under no obligation to provide any Information. However, if you should choose to withhold requested
      Information, you may not be able to access or utilise the Subscription Services.</p>
    <p>&nbsp;</p>
    <p><strong>HOW WE USE YOUR INFORMATION</strong></p>
    <p>We will use the Information to:</p>
    <ul>
      <li>help us to build a profile of your preferences so we can offer you recommendations, goods and services you are
        interested in;</li>
      <li>administer your account with us;</li>
      <li>contact you by email when necessary;</li>
      <li>improve the layout and/or content of the pages on the Application and customise them for users;</li>
      <li>carry out research on our users&rsquo; demographics;</li>
      <li>disclose to lawful authorities when required to do so by law;</li>
      <li>send you newsletters and recommendations; and</li>
      <li>enhance your user experience in general.</li>
    </ul>
    <p>We may also occasionally contact you to obtain feedback on a specific Application Service or suggest social
      connections.</p>
    <p>We may combine the Information you submit under your account with information from other Brand Ripplr services or
      Third Parties Services (as defined below) in order to provide you with a better experience and to improve the
      quality of our Subscription Services. For certain Subscription Services, we will give you the opportunity to opt
      out of combining such information.&nbsp;</p>
    <p>We do not sell, trade, or rent your personal identification information to others. We may share generic
      aggregated demographic information not linked to any personal identification information regarding visitors and
      users with our business partners, trusted affiliates and advertisers for the purposes outlined in this Privacy
      Policy or our Terms and Conditions of Use.</p>
    <p>&nbsp;</p>
    <p><strong>No Unlawful or Prohibited Use</strong></p>
    <p>You shall not, without Brand Ripplr&rsquo;s prior written permission, use the Application for purposes other than
      the Permitted Use. Without limiting the generality of the foregoing, you shall not, and shall not permit anyone
      else to:&nbsp;</p>
    <ol>
      <li>&ldquo;frame&rdquo;, &ldquo;mirror&rdquo; or otherwise incorporate the Application or the published post or
        any part thereof on any commercial or non-commercial website;</li>
      <li>access, monitor or copy any part of the Application or the published posts using any robot, spider, scraper or
        other automated means or any manual process for any purpose without our express written permission;</li>
      <li>violate the restrictions in any robot exclusion headers on the Application or published posts or bypass or
        circumvent other measures employed to prevent or limit access to the Application;</li>
      <li>modify or attempt to modify (or permit anyone else to modify or attempt to modify) the Application or any
        Events, including any modification for the purpose of disguising or changing any indications of the ownership or
        source of the Application or the Events;</li>
      <li>attempt to, assist, authorise or encourage others to circumvent, disable or defeat any of the security
        features or components, such as digital rights management software or encryption, that protect the Application;
      </li>
      <li>copy, reproduce, modify, distribute, transfer, sell, publish, broadcast, perform, transmit, publish, license
        or circulate in any form any part of the Application;</li>
      <li>create derivative works based on the Application or the published content, in whole or in part, or to
        decompile, disassemble, reverse engineer or other exploit any part of the Application or the published content;
      </li>
      <li>use or access the Application in a manner that violates the rights (including, but not limited to intellectual
        property rights) of any third party; or</li>
      <li>upload to or transmit through the Application any information, images, text, data, media or other content that
        is offensive, pornographic, hateful, obscene, defamatory or violates any laws or regulations (including but not
        limited to criminal laws and regulations), in each case as determined by Brand Ripplr in its sole discretion,
      </li>
      <li>post, upload, publish, submit or transmit any published content that:</li>
      <ul>
        <li>infringes, misappropriates or violates a third party&rsquo;s patent, copyright, trademark, trade secret,
          moral rights or other intellectual property rights, or rights of publicity or privacy;</li>
        <li>violates, or encourages any conduct that would violate, any applicable law or regulation or would give rise
          to civil liability;</li>
        <li>is fraudulent, false, misleading or deceptive;</li>
        <li>is defamatory, obscene, pornographic, vulgar or offensive;</li>
        <li>promotes discrimination, bigotry, racism, hatred, harassment or harm against any individual or group;</li>
        <li>is violent or threatening or promotes violence or actions that are threatening to any person or entity; or
        </li>
        <li>promotes illegal or harmful activities or substances, contains or promotes alcohol, tobacco or shisha or
          anything that is deemed harmful or illegal by applicable law or any competent regulatory authority.</li>
      </ul>
    </ol>
    <p>You shall be solely liable for any damages resulting from any violation of the foregoing restrictions, or any
      other harm resulting from reposting the published post(s).&nbsp;</p>
    <p>Brand Ripplr does not knowingly work with, collect any information or allow minors to download and install the
      Application.&nbsp;</p>
    <p>&nbsp;</p>
    <p><strong>Third Party Websites</strong></p>
    <p>The Application may provide links to third party websites. Brand Ripplr does not endorse the information
      contained on those web sites or guarantee their quality, accuracy, reliability, completeness, currency,
      timeliness, non-infringement, merchantability or fitness for any purpose. The content in any linked web site is
      not under Brand Ripplr&rsquo;s control, and if you choose to access any such website, you do so entirely at your
      own risk.&nbsp;</p>
    <p>Brand Ripplr may enable you to link your User Account with a valid account on a third party social networking,
      email or content service such as Facebook, YouTube, or Twitter, (such service, a &ldquo;Third-Party Service&rdquo;
      and each such account, a &ldquo;Third-Party Account&rdquo;) by allowing Brand Ripplr to access your Third-Party
      Account, as is permitted under the applicable terms and conditions that govern your use of each Third-Party
      Account. You represent that you are entitled to disclose your Third-Party Account login information to Brand
      Ripplr and/or grant Brand Ripplr access to your Third-Party Account (including, but not limited to, for use for
      the purposes described herein) without breach by you of any of the terms and conditions that govern your use of
      the applicable Third-Party Account and without obligating Brand Ripplr to pay any fees or making Brand Ripplr
      subject to any usage limitations imposed by such third-party service providers.&nbsp;</p>
    <p>By granting Brand Ripplr access to any Third-Party Accounts, you understand that Brand Ripplr may access, make
      available and store (if applicable) any information, data, text, software, music, sound, photographs, graphics,
      video, messages, tags and/or other materials accessible that you have provided to and/or stored in your
      Third-Party Account (&ldquo;TPS Content&rdquo;) so that it is available on and through the Brand Ripplr
      Application via your User Account. Depending on the Third-Party Accounts you choose and subject to the privacy
      settings that you have set in such Third-Party Accounts, personally identifiable information that you post to your
      Third-Party Accounts may be available on and through your User Account on the Brand Ripplr Application. You hereby
      authorise Brand Ripplr to retrieve information from, and submit information to, such Third Party Services at your
      request; and to provide services relating to any information we retrieve from those Third-Party Accounts. Please
      note that if a Third-Party Account or associated service becomes unavailable or Brand Ripplr&rsquo;s access to
      such Third-Party Account is terminated by the third-party service provider, then TPS Content will no longer be
      available on and through the Brand Ripplr materials. You have the ability to disable the connection between your
      User Account and your Third-Party Accounts at any time by accessing the &ldquo;Profile&rdquo; section. In most
      cases, we do not actually store the entirety of your TPS Content, but instead we collect and store the metadata
      associated with such TPS Content. You hereby authorised such collection and storage.</p>
    <p>Relationship with third-party services: Please note that your relationship with the third-party service providers
      associated with your third-party accounts is governed solely by your agreement(s) with such third-party service
      providers, and Brand Ripplr disclaims any liability for personally identifiable information that may be provided
      to it by such third-party service providers in violation of the privacy settings that you have set in such
      third-party accounts.</p>
    <p>Brand Ripplr makes no effort to review any TPS Content for any purpose, including but not limited to, for
      accuracy, legality or non-infringement, and Brand Ripplr is not responsible for any TPS Content. You should note
      that a Third-Party Service may change or amend its guidelines and our access to it at any time, and we cannot
      guarantee that our Services will always include a connection to such Third Party Service.</p>
    <p>We may hire other companies to perform certain business related functions such as for Subscription Services or
      processing payments on our behalf. Our payment-processing partner may request that you provide certain personal or
      other company data for the purpose of making payment through its financial institutions and complying with any
      federal and state laws and regulations. They may also communicate directly with you regarding any issues with a
      payment. Any information provided to our third party will be confidential and only be used for intended purposes.
      You must provide us with accurate, complete information and it is your responsibility to update and maintain
      changes to that information. We are entitled to rely on any information you provide to us.</p>
    <p>As we develop our business, we might sell or buy businesses or assets. In the event of a corporate sale, merger,
      reorganisation, dissolution or similar event, personal data may be part of the transferred assets.</p>
    <p>&nbsp;</p>
    <p><strong>Viruses</strong></p>
    <p>The installation and use of the Application is done at your own risk. Brand Ripplr cannot and does not guarantee
      or warrant that the Application is compatible with your mobile phone or computer system, or shall be free of
      viruses, worms, trojan horses or disabling devices or other code that manifests contaminating or destructive
      properties. You are responsible for implementing safeguards to protect the security and integrity of your mobile
      phone or computer system, and you are responsible for the entire cost of any service, repairs or connections of
      and to your mobile phone or computer system that may be necessary as a result of you installing the
      Application.&nbsp;</p>
    <p>&nbsp;</p>
    <p><strong>Communications and Confidentiality</strong></p>
    <p>Brand Ripplr does not guarantee the confidentiality of any and all communication made by you through the
      Application. Although Brand Ripplr generally adheres to the accepted industry practices in securing the
      transmission of data to, from and through the Application, you understand, agree and acknowledge that Brand Ripplr
      cannot and does not guarantee the security of data transmitted through our Application.&nbsp;</p>
    <p>You hereby acknowledge and agree that by using the Subscription Services you will be privy to confidential
      information.</p>
    <p>You undertake that you shall:</p>
    <ol>
      <li>keep the Confidential Information (as defined below) secret and confidential and shall take all necessary
        steps to preserve its confidentiality;</li>
      <li>not disclose or make available any Confidential Information to any person, except as permitted by these Terms
        and Conditions of Use and provided that it:</li>
      <ol>
        <li>informs the permitted recipient of the confidential nature of the Confidential Information before it is
          disclosed; and</li>
        <li>procures that the permitted recipient shall, in relation to any Confidential Information disclosed to it,
          comply with these Terms and Conditions of Use as if it were party thereto; and</li>
      </ol>
      <li>not use or exploit the Confidential Information in any way, except for the Permitted Use.</li>
    </ol>
    <p>&ldquo;Confidential Information&rdquo; means all confidential information (however recorded or preserved) that is
      disclosed or made available (in any form or by any method) by Brand Ripplr, the Influencers, the Application or in
      connection with an Event, including:</p>
    <ol>
      <li>the fact that discussions or negotiations are taking place concerning the Event and the published post and
        status of such discussions or negotiations;</li>
      <li>all confidential or proprietary information relating to the business or affairs, financial or trading
        position, assets, intellectual property rights, customers, clients, suppliers, employees, plans, operations,
        processes, products, intentions or market opportunities of Brand Ripplr or the Influencer as applicable;</li>
      <li>the know-how, designs, trade secrets, technical information or software of the&nbsp;Event Initiator or any
        member of its group; and</li>
      <li>any other information that is identified as being of a confidential or proprietary nature.</li>
    </ol>
    <p><strong>&nbsp;</strong></p>
    <p><strong>Marketing to you</strong>&nbsp;</p>
    <p>We may send you information we think you may find useful or which you have requested via the Application and/or
      (if you provide us with your e-mail address) by email, including information about the latest recommendations or
      features on Brand Ripplr, provided you have indicated that you do not object to being contacted for these purposes
      and we will always give you the option to opt-out of receiving further emails or push notifications by following
      the unsubscribe instructions on any communications sent to you. You can also exercise this right at any time by
      contacting us using the contact details.</p>
    <p>&nbsp;</p>
    <p><strong>Cookies Policy</strong></p>
    <p>Cookies/Web server logs: Similar to other commercial websites, our website (that we use for tracking) utilises
      &ldquo;cookies&rdquo; or similar technologies and web server logs to collect and store information about how our
      website is used and for marketing and retargeting. Cookies are a feature of web browser software that allows web
      servers to recognise the computer used to access a website. Cookies are small pieces of data that are stored by a
      user&rsquo;s web browser on the user&rsquo;s hard drive. Information gathered through cookies and web server logs
      may include the date and time of visits, the pages viewed, time spent at our website, and the web sites visited
      just before and just after our website. This information is collected on an aggregate basis. None of this
      information is associated with you as an individual.&nbsp;</p>
    <p>You can, of course, disable cookies on your computer by indicating this in the preferences or options menus in
      your browser. However, it is possible that some parts of our website will not operate correctly if you disable
      cookies. You should consult with your browser&rsquo;s provider/manufacturer if you have any questions regarding
      disabling cookies.</p>
    <p>Third Party Services: We may use services hosted by third parties including but not limited to Google analytics,
      Inspeclet, AWS to assist in providing our services and to help us understand how our websites are used. These
      services may collect information sent by a browser as part of a web page request, including IP addresses or
      cookies. If these third party services collect information, they do so anonymously and in the aggregate to provide
      information helpful to us such as website trends, without identifying individual visitors. Please see
      &ldquo;Cookies&rdquo; in the section above for information on how you can control the use of cookies on your
      computer.</p>
    <p>&nbsp;</p>
    <p><strong>Disclaimer and limitation of liability</strong></p>
    <p>Each party hereby represents and warrants that: (1) it has the legal right and authority to enter into these
      Terms and Conditions of Use; (2) these Terms and Conditions of Use form a binding legal obligation on behalf of
      such party; and (3) it has the legal right and authority to perform its obligations under these Terms and
      Conditions of Use and to grant the rights and licenses described in these Terms and Conditions of Use.&nbsp;</p>
    <p>The Application and the Application Content are provided &ldquo;as is&rdquo; without warranty or condition of any
      kind. Use of the Application or the Application Content is at your own risk. Brand Ripplr does not make any
      representations, warranties or conditions about the quality, accuracy, reliability, completeness, currency, or
      timeliness of the platform or the content. The Application and the Application Content may include errors,
      omissions and inaccuracies, including, without limitation, pricing errors. Brand Ripplr does not assume any
      responsibility for any errors, omissions or inaccuracies in the platform or the content. In addition, Brand Ripplr
      expressly reserves the right to correct any pricing errors on the platform.</p>
    <p>Influencer reviews displayed on the Application are intended as only general guidelines, and Brand Ripplr does
      not guarantee the accuracy of the reviews. Brand Ripplr makes no guarantees about the availability of specific
      influencers.</p>
    <p>To the fullest extent permitted by law, Brand Ripplr disclaims all warranties, representations and conditions of
      any kind with respect to the Application, the Application Content, the Subscription Services and any other content
      whether express, implied, statutory or collateral, including, without limitation, the implied warranties and
      conditions of merchantability, fitness for a particular purpose and non-infringement or that the platform or the
      content are or will be error-free or will operate without interruption. In no event will Brand Ripplr be liable,
      whether based on warranty, contract, tort, negligence, strict liability or any other legal theory, for any damages
      of any kind (including, without limitation, direct, indirect, incidental, consequential, special, exemplary or
      punitive damages, lost profits, loss of use, loss of data, personal injury, fines, fees, penalties or other
      liabilities), whether or not Brand Ripplr is advised of the possibility of such damages, resulting from or related
      to the use of, or the inability to make use of, the Application, the Application Content, the Subscription
      Services or any other published post or any agreement relating to an Event.</p>
    <p>Influencers engaging your services through the Application are independent individuals and not contractors,
      agents or employees of Brand Ripplr. Brand Ripplr is not liable for the acts, errors, omissions, representations,
      warranties, breaches or negligence of any Influencer or for any personal injuries, death, property damage, or
      other damages or expenses resulting therefrom and takes no responsibility whatsoever related to any Events or
      agreements relating to those Events.</p>
    <p>Some jurisdictions do not allow the exclusion or limitation of liability for consequential or incidental damages,
      so the above limitation may not apply to you. To the extent that the foregoing limitation does not apply, in no
      event will the total aggregate liability of Brand Ripplr in connection with or under these Terms and Conditions of
      Use, or your use of, or inability to make use of, the Application or the Application Content, exceed one hundred
      dollars (US$100) or the equivalent in local currency. For greater certainty, the existence of one or more claims
      under these Terms and Conditions of Use will not increase the maximum liability amount.</p>
    <p>The limitations above reflect the allocation of risk between the parties. The limitations specified in this
      section will survive and apply even if any limited remedy specified in these terms is found to have failed of its
      essential purpose.</p>
    <p>Limitations period. You and Brand Ripplr agree that any cause of action arising out of or related to these Terms
      and Conditions of Use or Privacy Policy, the Application or the Subscription Services must commence within one (1)
      year after the cause of action accrues or becomes known to Brand Ripplr. Otherwise, such cause of action is
      permanently barred.</p>
    <p>&nbsp;</p>
    <p><strong>Indemnification</strong></p>
    <p>You shall defend, indemnify and hold harmless Brand Ripplr and all of its officers, directors, employees and
      agents from and against any claims, causes of action, demands, recoveries, losses, damages, fines, penalties or
      other costs or expenses of any kind or nature including but not limited to reasonable legal and accounting fees,
      brought in connection with or as a result of: (a) your breach of any of your warranties, undertakings,
      representations or obligations under these Terms and Conditions of Use or any documents referenced herein; (b)
      your violation of any law (including without limitation any legal requirements or guidelines) or the rights of a
      third party (including, without limitation, intellectual property rights); (c) your use of the Application; (d)
      breach of or failure to perform under any agreement by you or by any third party acting on your behalf or with
      your permission with an Influencer; or (d) the use of any proprietary post, including without limitation design,
      video, music by you without approval from relevant third parties to do so or the use of any social media content
      reposted by you, or third parties on your behalf in connection with an Event.</p>
    <p>&nbsp;</p>
    <p><strong>Governing Law and Jurisdiction</strong></p>
    <p>These Terms and Conditions of Use and any action related thereto shall be governed by the laws of the United Arab
      Emirates as applicable in Dubai without regard to its conflict of laws provisions. These laws apply to your access
      to or use of the Application, notwithstanding your domicile, residency or physical location. The Application is
      intended for use only in jurisdictions it may lawfully be offered for use. Except as restricted by applicable law,
      you hereby consent to the exclusive jurisdiction&nbsp; of the courts of the Dubai International Financial Centre,
      United Arab Emirates in all disputes arising out of or relating to the use of the Application.</p>
    <p>&nbsp;</p>
    <p><strong>Entire Agreement, Waiver and Severability</strong></p>
    <p>The Terms and Conditions of Use constitute the entire agreement between Brand Ripplr and you pertaining to the
      subject matter hereof and supersede all prior or contemporaneous communications and proposals, whether electronic,
      oral or written, between you and Brand Ripplr with respect to the Application. A printed version of these Terms of
      and Conditions Use and of any notice given in electronic form shall be admissible in judicial or administrative
      proceedings based upon or relating to these Terms and Conditions of Use to the same extent and subject to the same
      conditions as other business documents and records originally generated and maintained in printed form.</p>
    <p>Brand Ripplr&rsquo;s failure to insist upon or enforce strict performance of any provision of these Terms and
      Conditions of Use shall not be construed as a waiver of any provisions or right. The waiver of any such right or
      provision will be effective only if in writing and signed by a duly authorised representative of Brand Ripplr.
      Except as expressly set forth in these Terms and Conditions of Use, the exercise by either party of any of its
      remedies under these Terms and Conditions of Use will be without prejudice to its other remedies under these Terms
      and Conditions of Use or otherwise.&nbsp;</p>
    <p>If for any reason a court of competent jurisdiction finds any provision of these Terms and Conditions of Use
      invalid or unenforceable, that provision will be enforced to the maximum extent permissible and the other
      provisions of these Terms and Conditions of Use will remain in full force and effect.&nbsp;</p>
    <p>If any of the provisions contained in these Terms and Conditions of Use conflict with the terms of another
      agreement between the parties, then these Terms and Conditions of Use shall prevail.&nbsp;</p>
    <p>&nbsp;</p>
    <p>Updated: August 2021</p>
    <p>&nbsp;</p>
    <p><strong>&nbsp;</strong></p>
  </div>
</div>
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyCh0A9UnDOP0aMPNqlgIDnfS8UCWLZlZTM",
    authDomain: "buzzbee-8483f.firebaseapp.com",
    databaseURL: "https://buzzbee-8483f.firebaseio.com",
    projectId: "buzzbee-8483f",
    storageBucket: "buzzbee-8483f.appspot.com",
    messagingSenderId: "300273996576",
    appId: "1:300273996576:web:7a8600b1061843a52fbd45",
    measurementId: "G-GPDZESF59P"
  }
};
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyD9bMaaJ1KPETC8b7-ZX0mVL0C0AXA9JlU",
//   authDomain: "buzzbee-staging-8b645.firebaseapp.com",
//   databaseURL: "https://buzzbee-staging-8b645.firebaseio.com",
//   projectId: "buzzbee-staging-8b645",
//   storageBucket: "buzzbee-staging-8b645.appspot.com",
//   messagingSenderId: "305747960804",
//   appId: "1:305747960804:web:d09d5f6d8f38ba07f35df1",
//   measurementId: "G-F23F27VLRX"
// };
// export const environment = {
//   production: false,
//   firebase: firebaseConfig
// };
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

/* src/app/terms/influencer/terms.component.scss */
.header {
  padding: 20px 0 20px;
  margin: 0;
}
.header h2 {
  font-size: 45px;
  padding: 0;
  margin: 0;
  text-align: center;
}
.header strong {
  display: block;
  text-align: center;
  font-size: 30px;
}
.content {
  font-size: 20px;
  padding-bottom: 30px;
  overflow-wrap: anywhere;
}
@media (max-width: 1140px) {
  .header h2 {
    font-size: 28px;
  }
  .header strong {
    display: block;
    text-align: center;
    font-size: 20px;
  }
  .content {
    font-size: 16px;
    padding: 0 20px 20px;
  }
}
/*# sourceMappingURL=terms.component.css.map */
